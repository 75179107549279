@use 'assets/scss/variables' as v;
// ----------------------- onboarding screen
.onboardingContainer {
  display: flex;
  gap: 48px;
  padding: 16px;
  @media (min-width: v.$lg) {
    padding: 24px 48px;
  }
}

.onboardingContainerLeft {
  max-width: 775px;
  display: none;
  @media (min-width: v.$lg) {
    display: block;
  }
}

.video {
  height: calc(100vh - 48px);
  width: auto;
  position: sticky;
  top: 24px;
}

.onboardingContainerRight {
  margin: 0 auto;

  @media (min-width: v.$lg) {
    flex-shrink: 0;
  }
}
