@use "assets/scss/variables" as v;
.containerhoverlay {
  background-color: rgba(0, 0, 0, 0.7019607843);
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 0 10px;
  animation: slide-down 0.2s ease;
  .customInner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .container_content {
    padding: 40px 61px;
    width: 821px;
    background: #ffffff;
    box-shadow: 0px 4px 50px 20px rgb(0 0 0 / 10%);
    border-radius: 16px;
    position: relative;
    overflow: auto;
    height: auto;
    @media (max-width: 792px) {
      padding: 10px 15px;
    }
    .popupImage,
    .reviewImage {
      width: 212px;
      height: 212px;
      margin: -30px auto 30px;
      position: relative;
      @media (max-width: 792px) {
        margin: 25px auto;
      }
    }
    .reviewImage {
      margin: 50px auto 30px;
    }
    .popupTitle {
      text-align: center;
      font-size: 2.125rem;
      font-weight: 800;
      color: #515254;
      @media (max-width: 792px) {
        font-size: 1.5rem;
      }
    }
    .crossIcon {
      position: absolute;
      right: 35px;
      top: 35px;
      cursor: pointer;
    }
    .popupDesc {
      text-align: center;
      font-size: 20px;
      max-width: 547px;
      margin: 16px auto 40px;
      font-weight: 400;
      color: #3e3e3e;
      @media (max-width: 792px) {
        font-size: 0.875rem;
        margin: 15px auto;
      }
    }
  }
}

.error {
  composes: error from "assets/scss/pages/profile.module.scss";
}
