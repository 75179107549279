@use 'assets/scss/variables' as v;

// --------------------------signup screen
.signupInnerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  padding: 40px 0px;
  height: 100%;
  max-width: 480px;
  width: 100%;
}

.linkSocialInfo {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.signupItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  h1 {
    font-size: 24px;
    line-height: 1.6;
    color: v.$txt-color;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  p {
    color: v.$primary-dark;
    font-size: 0.875em;
    line-height: 1.4;
    font-weight: 400;
  }
}

.signupFormItems {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  input,
  textarea {
    width: 100%;
    outline: none;
    padding: 13px 18px;
    box-shadow: -15px 10px 20px #d0d0d059;
    border-style: solid;
    border-top-color: v.$white;
    border-left-color: v.$white;
    border-right-color: v.$white;
    border-bottom: 2px solid v.$input-border;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    color: v.$primary-dark;

    &:not(:placeholder-shown) {
      border-color: v.$pink;
    }
  }

  .passwordWrapper {
    position: relative;

    .eyeImage {
      height: 18px;
      width: 23px;
      position: absolute;
      right: 18px;
      top: 14px;
      cursor: pointer;
    }
  }

  .mobileWrapper {
    position: relative;

    input {
      padding-left: 120px;
    }

    .countryCodeSelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .error {
    color: #f64242;
    margin-top: 6px;
    font-size: 14px;
  }
}

.profile {
  input,
  textarea {
    box-shadow: -15px 10px 40px #d0d0d01c;
    padding: 13px 16px;

    &::placeholder {
      font-weight: 400;
    }
  }
}

.addressTitle {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: v.$txt-color;
}

.addressFields {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr;

    & .select {
      max-width: 228px;
    }
  }
}

.signupLink {
  margin: 34px 0px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 500;
  color: #828282 !important;

  a {
    font-weight: 600;
    color: #931497;
    text-decoration: underline;
  }
}

// --------------------otp verify screen
.otpVerification {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 11px;

  input {
    width: 100%;
    text-align: center;
    box-shadow: -15px 10px 40px #d0d0d01c;
    outline: none;
    border: none;
    border-bottom: 2px solid v.$input-border;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 500;
    color: v.$primary-dark;
    padding: 10px 0;

    // Following removes number input arrow keys
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.resentBtn {
  margin: 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    color: v.$pink-dark;
    background: none;
    outline: none;
    border: none;
    font-size: 0.875em;
    line-height: 1.2;
    font-weight: 600;
    cursor: pointer;
  }

  p {
    font-size: 0.875em;
    line-height: 1.2;
    font-weight: 600;
    color: v.$pink-dark;
  }
}

// ----------------------------- sign-up profile setup
.uploadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .uploadPdfDetail {
    font-size: 0.875em;
    color: v.$txt-color;
    line-height: 1.5;
    font-weight: 600;
  }

  .documentFormat {
    font-size: 0.75em;
    color: v.$primary-dark;
    line-height: 1.5;
    font-weight: 400;
  }

  .uploadBtn {
    cursor: pointer;
    border: 1px dashed v.$primary-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    gap: 10px;
    background: v.$white;
    color: v.$primary-dark;
    font-size: 0.875em;
    line-height: 1.3;
    font-weight: 400;
    max-width: 160px;
    width: 100%;
  }

  .filename {
    font-size: 14px;
    line-height: 1.4;
    color: v.$black-light;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  .removeBtn {
    text-decoration: underline;
    font-weight: 400;
  }
}

//-------------------------checkbox
.checkbox {
  display: grid;
  grid-template-columns: 1.25rem auto;
  gap: 0.875rem;
  align-items: center;

  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: v.$white;
    margin: 0;
    padding: 0;
    cursor: pointer;

    font: inherit;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid v.$primary-dark;
    border-radius: 0.25rem;
    position: relative;

    display: grid;
    place-content: center;

    &:checked {
      background: v.$primary-dark;

      &::before {
        content: '';
        width: 6px;
        height: 12px;
        border: solid v.$white;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        transform: rotate(45deg) translateY(-1px) translateX(-1px);
      }
    }
  }

  label {
    font-size: 0.875em;
    line-height: 1.4;
    font-weight: 500;
    color: v.$txt-color;

    a {
      font-weight: 600;
      text-decoration: none;
      color: v.$pink-dark;
    }
  }
}

// ----------------------- influencer signup user profile setup

.userSelection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + label {
    background: rgba(v.$primary, 0.16);
  }

  label {
    border: 1px solid v.$primary;
    padding: 10px 17px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    span {
      font-size: 18px;
      color: v.$primary-dark;
      font-weight: 600;
      line-height: 1.3;
      transition: all 0.3s ease;
      display: inline-block;
      transform-origin: left;
    }
  }
}

.profileImageUpload {
  position: relative;
  width: 111px;

  img {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid v.$primary-dark;
  }

  .uploadIconImage {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.managerQueryWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;

  .yesNoButtons > * {
    flex-shrink: 0;
  }

  .yesNoButtons {
    display: flex;
    gap: 16px;

    & > button {
      min-width: 70px;
      border: 1px solid #d633db;
      color: #7c087f;
      font-size: 12px;
      font-weight: 600;
      padding: 8px;

      &.activeBtn {
        border-width: 2px;
        background: #feeaff;
      }
    }
  }
}

// -----------------------------login style
.forgotPassword {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;

  a {
    font-size: 0.875em;
    line-height: 1.4;
    font-weight: 500;
    color: #515254;
    text-decoration: underline;
  }
}

// ----------------------------- Link social accounts

.accountLink {
  align-items: flex-start;
  composes: accountLink from './profile.module.scss';
}

.socialInputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 16px;
  height: 100%;
}

.withHelperText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.helperText {
  color: #292930;
  margin-top: 6px;
  font-size: 12px;
}

.socialInputInfo {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.socialTxt {
  color: #900794;
  font-size: 1rem;
  font-weight: 500;
}

.verifyBtnWrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.verify {
  font-size: v.$spacing-3;
  line-height: 2;
  font-weight: 600;
  color: #ff00a2;
  border: 0.4000000059604645px solid #7c087f;
  background: #fc9fff3b;
  padding: 7px 0px;
  width: 71px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.verify:hover {
  background: #840d883b;
}

.activeVerifyBtn:hover {
  background-color: rgba(173, 0, 0, 0.733);
  color: white;
  border: 0.4000000059604645px solid #acf3cc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activeVerifyBtn {
  border: 0.4000000059604645px solid #acf3cc;
  background: #45bc612e;
  color: #45bc61;
}

.socialImage {
  composes: socialImage from './profile.module.scss';
}

.costContainer {
  composes: costContainer from './search.module.scss';

  & td {
    padding: 10px 20px;
  }
}
.socialList {
  composes: socialList from './search.module.scss';
}
.rateInputWrapper {
  composes: rateInputWrapper from './search.module.scss';
}
.rateInput {
  composes: rateInput from './search.module.scss';
}
