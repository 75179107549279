:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #e1f0f9;
  --toastify-color-success: #ebf9eb;
  --toastify-color-warning: #f9f5e1;
  --toastify-color-error: #fae7e5;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 340px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 50px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Poppins, -apple-system, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #14171f;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #14171f;
  --toastify-text-color-success: #14171f;
  --toastify-text-color-warning: #14171f;
  --toastify-text-color-error: #14171f;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
  box-shadow: inset 6px 8px 17px #fac2fc40, 0px 16px 50px #c3c3c333;
}

.Toastify__toast-body {
  font-size: 0.875rem;
  letter-spacing: 0.18px;
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    color: #e74c3c;
  }
}

.Toastify__toast--info {
  .Toastify__toast-icon {
    color: #3498db;
  }
}
.Toastify__toast--info {
  .Toastify__toast-icon {
    color: #3498db;
  }
}

.Toastify__toast--warning {
  .Toastify__toast-icon {
    color: #f1c40f;
  }
}

.Toastify__toast--success {
  .Toastify__toast-icon {
    color: #01b000;
  }
}
