.subscriptionPlanContainer {
    position: relative;
}
.subscriptionHeader {
    background: #7c087f;
    padding-top: 70px;
    @media (max-width:992px) {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.planHeading {
    color: #ffffff;
    font-size: 2.688rem;
    text-align: center;
    line-height: 2;
    font-weight: 600;
    @media (max-width:992px) {
        font-size: 2rem;
        line-height: 1.4;
    }
}

.planTxt {
    color: #fae8fb;
    font-size: 1.313rem;
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
    padding-top: 13px;
}

.frameBtns {
    display: flex;
    justify-content: center;
    padding-top: 53px;
}

.monthlyBtn {
    border-radius: 4px 0px 0px 4px;
    background: #d633db;
    padding: 10px 30px;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 400;
}
.quartlyBtn {
    border-radius: 0px;
}
.quartlyActiveBtn {
    background: #f2d8f2;
    color: #7c087f;
    font-weight: 600;
}

.yearlyBtn {
    border-radius: 0px 4px 4px 0px;
}

.container {
    composes: container from "../common.module.scss";
}

.planBox {
    margin-top: 80px;
    padding-bottom: 170px;
    display: flex;
    gap: 55px;
    justify-content: space-between;
    @media (max-width:992px) {
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 20px;
    }
}

.block1 {
    border-radius: 1px;
    background: #d633db0d;
    padding: 39px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blockHeadingTxt {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #000000;
    font-weight: 600;
}

.detailTxt {
    font-size: 1.25rem;
    color: #7c287d;
    font-weight: 800;
    text-align: center;
    margin-top: 10px;
}
.detailInnerTxt {
    font-weight: 600;
    font-size: 0.975rem;
}
.subBlock2 {
    padding-top: 25px;
}
.planListContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.planInnrList {
    color: #676367;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    padding-left: 19px;
    position: relative;
}

.planInnrList::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    background: #af66b1;
    border-radius: 50%;
    width: 4px;
    height: 4px;
}

.subBlock3 {
    padding-top: 25px;
    display: flex;
    justify-content: center;
}

.buyNowBtn {
    border: 1px solid #7c087f;
    width: 100%;
    padding: 12px 0px;
    font-size: 1rem;
    font-weight: 600;
    color: #7c087f;
    outline: none;
}

.block2 {
    box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
    background: #fff;
    transform: scale(1.15);
}

.activebuyBtn {
    background: transparent linear-gradient(99deg, #7c087f 0%, #a506aa 100%);
    color: #ffffff;
}

.backgroundImg {
    position: absolute;
    top: 16%;
    right: 5px;
    @media (max-width:992px) {
        display: none;
    }
}
.backgroundImg2 {
    position: absolute;
    left: 0;
    bottom: -40px;
    @media (max-width:992px) {
        display: none;
    }
}
