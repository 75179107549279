@use 'assets/scss/variables' as v;

.wrapper {
  &.disabled {
    opacity: 0.6;
  }
}

.label {
  font-size: 1rem;
  color: #19191c;
  line-height: 1.5;
  font-weight: 600;
}

.selectButton {
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff80;
  padding: 10px 12px;
  height: 40px;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
  opacity: 1 !important;

  .selectItem {
    display: flex;
    align-items: center;
    gap: 16px;

    & img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .selectItemTxt {
    white-space: nowrap;
    color: #1f2933;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectOptions {
  max-height: 240px;
  width: 100%;
  background: v.$white;
  padding: 4px 0;
  z-index: 1;
  outline: none;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  overflow: auto;
}

.selectOption {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: v.$txt-color;
  padding: 4px 12px;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;

  .optionLabel {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.active {
    background: rgba(v.$primary, 0.12);
  }

  &.disabled,
  &:disabled {
    opacity: 0.6;
  }

  .checkMark {
    flex: 0 0 auto;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 2px solid v.$pink;
    border-radius: 0.25rem;
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: v.$white;
  }
}
.selectOptionActive {
  //   color: v.$primary-dark;

  .checkMark {
    background: v.$pink;

    &:after {
      content: '';
      background-color: transparent;
      width: 5px;
      height: 10px;
      border: solid v.$white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateY(-1px) translateX(-1px);
    }
  }
}

.rangeSelectorPopover {
  composes: selectOptions;
  padding: 12px;
  min-width: 240px;
  width: 100%;
}

.rangeSelectorHeading {
  color: #1f2933;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.rangeSliderSection {
  margin: 4px;
}

.rangeInputSection {
  display: flex;
  gap: 12px;
}

.rangeFormGroup {
  width: 100%;

  .rangeLabel {
    color: #616e7c;
    font-size: 12px;
    font-weight: 600;
  }

  .rangeInput {
    border: 1px solid v.$pink;
    border-radius: 4px;
    padding: 5px 10px 3px;
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.13px;
    line-height: 1.1;
  }
}

.rangeSelectorFooter {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 16px;
}

.clearButton {
  padding: 7px;
  color: #757575;
  font-size: 12px;
  font-weight: 600;
}

.applyButton {
  background: transparent linear-gradient(106deg, #7c087f 0%, #a506aa 100%) 0%
    0% no-repeat padding-box;

  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 32px;
}
