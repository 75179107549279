/*
when you make any changes in this file make sure to export it in a css file 
and replace campaign-report.css file in public folder
*/

.reportContainer {
  margin: auto;
  padding: 0 26px;
  margin-top: 26px;
  overflow: hidden;

  @media (max-width: 992px) {
    padding: 0 16px;
  }
}
.sectionForExport {
  width: 1024px !important;
  height: 0px;
  overflow: hidden;

  .insightItems {
    flex-wrap: wrap;
  }
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap: 16px;
  flex-wrap: wrap;

  .headerActions {
    display: flex;
    gap: 20px;

    .actionBtn {
      background: transparent linear-gradient(101deg, #7c087f 0%, #a506aa 100%);
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 14px 20px;
      font-size: 0.75rem;
      line-height: 1.4;
      color: #fff;
      font-weight: 600;
      flex-wrap: nowrap;
    }
  }
}
.metricCardsWrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (min-width: 1280px) {
    gap: 40px;
    flex-wrap: nowrap;
  }

  .metricCard {
    box-shadow: 0px 2px 10px #ff00a24d;
    border: 1px solid #ffffff;
    border-radius: 1px;
    position: relative;
    display: flex;
    gap: 22px;
    align-items: center;
    padding: 8px 24px;
    width: 100%;

    .metricCardBg {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: auto;
      object-fit: cover;
    }

    .metricImage {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    .metricTitle {
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0px;
      color: #7c087f;
    }

    .metricValue {
      font-size: 26px;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ff00a2;
    }
  }
}

.analyticsSection {
  border: 1px solid #e6e6e6;
  margin: 25px 0px;
  padding: 16px;
  width: 100%;
  break-after: auto;
  break-before: auto;
  break-inside: avoid;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .title {
      color: #616e7c;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 600;
    }
  }

  .filterList {
    display: flex;
    gap: 18px;
    border-bottom: 1px solid #ecdaec;

    .filterBtn {
      color: #616e7c;
      text-transform: capitalize;
      font-size: 0.875rem;
      line-height: 1.4;
      font-weight: 400;
      cursor: pointer;
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;

      &.active {
        color: #d633db;
        font-weight: 600;
        border-bottom: 2px solid #d633db;
      }
    }
  }

  .contentWrapper {
    margin-top: 24px;
    display: flex;
  }

  .posts {
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
  }
}

.videoStatsContainer {
  display: flex;
  gap: 50px;

  & > .statsWrapper {
    padding: 25px 0 0;

    .statsTitle {
      display: flex;
      align-items: center;
      gap: 10px;

      .statsDot {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #7c087f;
      }

      .statsTitleText {
        color: #999e9d;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.5;
      }
    }

    .statsValue {
      color: #323736;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.4;
      margin-top: 5px;
    }
  }
}

.postWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 205px;

  .postHeader {
    display: flex;
    align-items: center;
    gap: 12px;

    & img {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 25px;
    }

    & .title {
      color: #14171f;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.2;
    }
  }

  .postContent {
    display: block;
    position: relative;

    & .postImage {
      background-color: #e4e7eb;
      border-width: 0;
      height: 204px;
      object-fit: cover;
      width: 204px;
    }

    & .overlayIcon {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    & .postDetails {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }

    & .postsDetailsItem {
      display: flex;
      gap: 5px;
      margin-left: 8px;
    }

    & .viewText {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.4;
      color: #616e7c;
    }
  }
}

.insightItems {
  display: flex;
  gap: 25px;
  width: 100%;

  & > div {
    margin: 0;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}
