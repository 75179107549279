.header {
  composes: header from '../../../components/common/Header/Header.module.scss';
  box-shadow: none;
}
.navContainer {
  composes: navContainer from '../../../components/common/Header/Header.module.scss';
}
.show {
  composes: show from '../../../components/common/Header/Header.module.scss';
}
.closeBtnSpacer {
  composes: closeBtnSpacer from '../../../components/common/Header/Header.module.scss';
}
.navLinks {
  composes: navLinks from '../../../components/common/Header/Header.module.scss';
}
.navBtn {
  composes: navBtn from '../../../components/common/Header/Header.module.scss';
}
.navItems {
  composes: navItems from '../../../components/common/Header/Header.module.scss';
}
.navCheck {
  composes: navCheck from '../../../components/common/Header/Header.module.scss';
}
.navOpen {
  composes: navOpen from '../../../components/common/Header/Header.module.scss';
}
.brandSignUpBtn {
  border: 1px solid #7c087f;
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #7c087f;
  transition: all 0.2s ease;

  &:hover {
    background: #d633db1a;
    box-shadow: 0px 0px 4px #fca2ff99;
  }
}

.influencerSignUpBtn {
  border: 1px solid #ff00a2;
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #ff00a2;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0px 0px 4px #ff9ddb;
    background: #ff00a21a;
  }
}
.bannerHeading {
  text-align: center;
  width: 100%;
  max-width: 992px;
  margin: auto;
  left: 0;
  @media (max-width: 992px) {
    // padding: 10px;
  }
}
.bannerWrapper {
  margin-top: -110px;
  position: relative;
  height: 1180px;
  @media (max-width: 992px) {
    height: 620px;
  }
  @media (max-width: 645px) {
    height: 750px;
  }
  @media (max-width: 576px) {
    height: 800px;
  }
}
.bannerWrapper {
  & > .backgroundSvg,
  & > .backgroundDotsImage {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  & > .backgroundSvg {
    z-index: -3;
  }

  & > .backgroundDotsImage {
    z-index: -2;
    mix-blend-mode: color-burn;
  }
}
.bannrInnrWrapper {
  padding-top: 150px;
  overflow: hidden;
}
.headingTxt {
  text-wrap: balance;
  font-size: 46px;
  line-height: 69px;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 992px) {
    font-size: 34px;
    line-height: 1.4;
  }
  @media (max-width: 767px) {
    font-size: 28px;
  }
}

.paragraphTxt {
  color: #ffffff;
  opacity: 0.9;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 20px;
  text-wrap: balance;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 26px;
  }
}
.bannerBtns {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}
.bannerBtns a {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  max-width: 210px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
.startCampBtn {
  background: transparent
    linear-gradient(103deg, #ffffff 0%, #ffffff 30%, #ffffff 100%);
  padding: 14px 21px;
  color: #8a098d;
}

.bookCampBtn {
  border: 1px solid #ffffff;
  padding: 14px 32px;
  color: #ffffff;
}
.multiImages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 0 30px 70px;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  @media (max-width: 992px) {
    display: none;
  }
  @media (max-width: 1280px) {
    transform: scale(0.8);
  }
}

.blockImg1 {
  width: 40%;
  max-width: 380px;
  @media (max-width: 1100px) {
    width: 100%;
  }
}
.rankImg {
  margin: auto;
  border-radius: 12px;
  width: 376px;
}
.rank2Img {
  margin: auto;
  border-radius: 12px;
}
.titleTxt {
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  margin: 7px auto 0px;
  width: 100%;
  max-width: 310px;
  margin-top: 14px;
  text-wrap: balance;
  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.blockImg2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(2.5vw);
  @media (max-width: 1100px) {
    width: 100%;
    transform: translateX(0);
  }
}
.graph1Img {
  width: 100%;
  max-width: 180px;
  margin: auto;
}
.blockImg3 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 117px;
  width: 30%;
  max-width: 350px;
  transform: translateX(200px) translateY(-50px);

  @media (max-width: 1439px) {
    transform: translateX(117px) translateY(0px);
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
}
.img1 {
  position: absolute;
  left: -240px;
}
.img2 {
  position: absolute;
  top: 240px;
  left: -220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.graph2Img {
  width: 100%;
  max-width: 225px;
  margin: auto;
}
.title2Txt {
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  width: 100%;
  max-width: 208px;
  margin-top: 12px;
}
.MessageSubjectIcons {
  display: inline;
}
h2.message-subject {
  font-size: 16px;
  color: #fff;
  margin: 0px;
}

.messageBodyCheckmark {
  position: relative;
  float: left;
  padding: 10px;
}

.messageBodyCheckmark:before {
  content: '';
  background: url(../../images//youtube-img.svg) no-repeat;
  display: inline-block;
  margin-right: 4px;
  position: absolute;
  top: 6px;
  left: -3px;
  height: 14px;
  width: 21px;
}

.videoTxt {
  font-size: 16px;
  line-height: 1.7;
  width: 100%;
  margin-right: 38px;
  color: #ffffff;
  margin-top: 10px;
  max-width: 250px;
}
// ========================== second Section ============================
.elevationBrands {
  margin-top: 110px;
  margin-bottom: 70px;
  @media (max-width: 992px) {
    padding: 0 20px;
    margin-bottom: 40px;
  }
}
.elevationTxt {
  font-size: 50px;
  line-height: 70px;
  color: #24263a;
  text-align: center;
  font-weight: 800;
  width: 100%;
  margin: auto;
  max-width: 1100px;
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 38px;
    max-width: 100%;
  }
}
.brandsInnrSec {
  display: flex;
  gap: 120px;
  margin-top: 41px;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 45px;
  }
}
.elevatingBg {
  position: absolute;
  top: 80px;
  z-index: -1;
  @media (max-width: 992px) {
    left: 0;
  }
}
.listItem {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.listTxt {
  font-size: 22px;
  line-height: 35px;
  font-weight: 500;
  color: #383838;
  position: relative;
  width: 100%;
  max-width: 593px;
  @media (max-width: 992px) {
    max-width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 25px;
  }
}
.listTxt::before {
  background: url(../../images/load-img.png) no-repeat;
  content: '';
  height: 24px;
  left: -34px;
  position: absolute;
  top: 8px;
  width: 24px;
  @media (max-width: 992px) {
    left: -25px;
    top: 4px;
  }
}
.prfileBtnContainer {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 992px) {
    margin: 20px 0px;
    justify-content: center;
  }
}
.createProfileBtn {
  background: transparent linear-gradient(104deg, #8a098d 0%, #c605cc 100%);
  padding: 14px 37px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

// ==================================== third section ==================
.exTxt {
  font-size: 20px;
  line-height: 35px;
  color: #808080;
  font-weight: 500;
  text-align: center;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 30px;
  }
}
.subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 100px;
  cursor: pointer;
  background: transparent
    linear-gradient(180deg, #ffffff 69%, #f2d8f2 275%, #f2d8f261 54%);
  @media (max-width: 992px) {
    gap: 30px;
  }
}

.subscribePlans {
  width: 100%;

  @media (max-width: 992px) {
    overflow: auto;
    width: 100%;
    height: auto;
  }
}
.subInnrPlanWrapper {
  display: flex;
  gap: 37px;
  justify-content: center;
  @media (max-width: 992px) {
    justify-content: initial;
    padding: 20px 10px;
  }
}
.subscriptionContainer {
  box-shadow: 0px 4px 30px #ffdbfd;
  border: 1px solid #ff00a21a;
  border-radius: 30px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 410px;
  @media (max-width: 992px) {
    min-width: 340px;
  }
}
.innerSubscriptionColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.innerSubscriptionColumn h2 {
  font-size: 36px;
  line-height: 35px;
  color: #24263a;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 35px;
}

.pricingExpiry h3 {
  font-size: 50px;
  font-weight: 600;
  color: #900794;
  margin-bottom: 15px;
}

.pricingExpiry h3 span {
  font-size: 20px;
}

.pHeight {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-left: 35px;
  height: 100%;
}
.trialTime p {
  color: #24263a;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  background: #f7d6f8;
  border-radius: 40px;
  padding: 11px 37px;
  @media (max-width: 992px) {
    text-align: center;
    font-size: 16px;
  }
}

.trialTime {
  margin-bottom: 50px;
}
.pHeight li {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.pHeight li:before {
  background: url(../../images/accept.svg) no-repeat;
  content: '';
  height: 24px;
  left: -34px;
  position: absolute;
  top: 3px;
  width: 24px;
}
.buyNowBtn {
  margin-top: 50px;
  width: 100%;
}
.editBooking {
  border: 1px solid #900794;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 16px 45px;
  color: #900794;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: 320px;
  margin: auto;
  @media (max-width: 992px) {
    font-size: 16px;
    padding: 16px 7px;
  }
}
.subscribeContainerActive .hoverBooking {
  background: #900794;
  color: #fff;
}
.subscribeContainerActive {
  box-shadow: 0px 4px 30px #ffdbfd;
  border: 3px solid #a807ac;
  border-radius: 30px;
  transform: scale(1.02);
  transition: 0.5s ease;
  background: #9007940d;
}

.subscribeBtns {
  box-shadow: 4px 4px 30px #ffdbfd;
  border: 1px solid #ff00a21a;
  border-radius: 100px;
  padding: 10px 15px;
  display: flex;
  align-items: center;

  & > button {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 20px;

    &.activeBtn {
      color: #fff !important;
      background: #d633db;
    }
  }

  @media (min-width: 640px) {
    padding: 6px 10px;
    & > button {
      font-size: 20px;
      padding: 16px 40px;
      border-radius: 40px;
    }
  }
}
// ============================fourth section========================

.contntImg {
  width: 100%;
  @media (max-width: 992px) {
    height: 90vh;
  }
}

.curatingContent {
  background: url(../../images/curate-img.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 0px;
  @media (max-width: 992px) {
    margin: 0px;
  }
}

.contentInnr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1084px;
  @media (max-width: 1600px) {
    height: 880px;
  }
  @media (max-width: 992px) {
    justify-content: center;
    flex-wrap: wrap-reverse;
    padding: 0 20px;
    height: auto;
  }
}
.contntSubBox {
  width: 100%;
  max-width: 749px;
  padding-left: 100px;
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 25px 0px;
    text-align: center;
  }
}
.curateTxt {
  font-size: 50px;
  line-height: 1.5;
  color: #24263a;
  font-weight: 800;
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}
.withNegativeMargin {
  font-size: 42px;
  margin-right: -310px;

  @media (max-width: 992px) {
    margin-right: 0;
  }
}

.curateDetailTxt {
  color: #383838;
  font-size: 22px;
  margin-top: 30px;
  line-height: 35px;
  font-weight: 500;
  @media (max-width: 992px) {
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
    line-height: 30px;
  }
}
.curteImgContainer {
  width: 100%;
  max-width: 1009px;
  overflow: hidden;
}
.curtePicture {
  width: 100%;
  max-width: 1024px;
  margin-left: 78px;
  @media (max-width: 992px) {
    margin: auto;
    max-width: 720px;
  }
}

// ================================== fifth Section
.langugeSec {
  display: flex;
  align-items: center;
  margin: 100px 0px;
  justify-content: center;
  position: relative;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    margin: 40px 0px;
    padding: 0 20px;
  }
}
.dotPattrnImg {
  position: absolute;
  left: 75%;
  top: 10%;
  @media (max-width: 1600px) {
    left: 70%;
  }
  @media (max-width: 1200px) {
    left: 66%;
  }
  @media (max-width: 1024px) {
    left: 60%;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

// ======================================= sixth section================
.influencerContainer {
  position: relative;
  background: url(../../images/management-img.png) no-repeat;
  background-size: 100% 100%;
}

.influncerInnrContnt {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 50px;
  height: 700px;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 70px;
    flex-wrap: wrap-reverse;
    height: auto;
    padding: 35px;
  }
}

.contnt1 {
  width: 100%;
  max-width: 657px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.contnt2 {
  width: 100%;
  max-width: 730px;
  @media (max-width: 992px) {
    max-width: 400px;
  }
}
.platformImg {
  transform: scale(1.1) translate(10px, 188px);
  // @media (max-width: 1600px) {
  //     transform: scale(1.1) translate(10px, 205px);
  // }
  @media (max-width: 1440px) {
    transform: scale(1.1) translate(10px, 243px);
  }
  @media (max-width: 1400px) {
    transform: scale(1.1) translate(10px, 254px);
  }
  @media (max-width: 1280px) {
    transform: scale(1.1) translate(10px, 286px);
  }
  @media (max-width: 992px) {
    transform: scale(1.1) translate(-8px, 17px);
  }
}
.bookUsBtn {
  background: #ffffff;
  color: #7e0981;
  font-size: 16px;
  font-weight: 500;
  padding: 14px 41px;
  @media (max-width: 992px) {
    display: block;
    margin: auto;
  }
}
.activeBorder {
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #b584ce26 61%, #641582cc 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 4px 5px 10px #e79ce380;
  border: 1.5px solid #fff;
  border-radius: 12px;
}
// ========================= influencer homepage
.influncrPostingContiner {
  width: 100%;
  max-width: 730px;
  @media (max-width: 1400px) {
    max-width: 585px;
  }
  @media (max-width: 1280px) {
    max-width: 495px;
  }
  @media (max-width: 992px) {
    max-width: 100%;
  }
}
.infImg {
  transform: scale(1.1) translate(-50px, 100px);
  @media (max-width: 1400px) {
    transform: scale(1.1) translate(-50px, 153px);
  }
  @media (max-width: 1280px) {
    transform: scale(1.1) translate(-50px, 185px);
  }
  @media (max-width: 992px) {
    transform: scale(1) translate(0px, 10px);
  }
}
.influenerBoostContainer {
  width: 100%;
  max-width: 640px;
  padding-left: 50px;
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 30px 0px;
  }
}
.wrapReverse {
  @media (max-width: 992px) {
    flex-wrap: wrap-reverse;
  }
}
.pinkBg {
  background: #ff00a2;
}
.listTxtSpan {
  color: #8a098d;
  font-weight: 800;
}
.brandContainer {
  width: 100%;
  max-width: 820px;
  padding-left: 60px;
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0;
  }
}
.sideEllipseImg {
  position: absolute;
  left: 82%;
  z-index: -1;
  top: 23%;
  height: 500px;
  @media (max-width: 1440px) {
    left: 78%;
  }
  @media (max-width: 1100px) {
    left: 74%;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.collabInnrContainer {
  width: 100%;
  max-width: 623px;
  @media (max-width: 992px) {
    padding: 0 14px;
  }
}
.dotImage {
  position: absolute;
  top: -75px;
  left: -72px;
  z-index: -1;
}
.dotLangSec {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 80%;
  @media (max-width: 992px) {
    display: none;
  }
}
.boostContentWrapper {
  background: url(../../images/boost-img.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 90px;
  @media (max-width: 992px) {
    margin: 0px;
  }
}
.boostContInnr {
  height: 800px;
  @media (max-width: 992px) {
    height: auto;
  }
}
.actYoutubeImg {
  background: transparent
    linear-gradient(90deg, #ffc4ea4d 0%, #f922ab66 0%, #5f003c 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 4px 5px 10px #ffffff80;
  border: 1.5px solid #ffffff;
  border-radius: 12px;
}
