.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: #1f2933;
  background: #fff;
  font-weight: 600;

  &.small {
    width: 28px;
    height: 28px;
    font-size: 0.75rem;
  }

  &.medium {
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }

  &.large {
    width: 45px;
    height: 45px;
    font-size: 1rem;
  }
}

.avatarImage {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  background: transparent;
  text-indent: 10000px;
}

.fallbackImage {
  composes: avatarImage;
  background: #ff00a240;
}

.avatarGroup {
  display: flex;

  .avatar {
    margin-left: -8px;
    border: 1px solid #fff;

    &:first-child {
      margin-left: 0;
    }
  }
}
