.headingContainer {
  composes: headingContainer from './static-content.module.scss';
}

.headingTxt {
  composes: headingTxt from './static-content.module.scss';
}

.container {
  composes: container from '../common.module.scss';
}

.aboutContainer {
  margin-top: 24px;
}

.ourStoryWrapper {
  background: #fef8fe;
  border-radius: 10px;
  padding: 44px 66px;

  @media (max-width: 992px) {
    padding: 16px;
  }
}

.ourStory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 58px;

  &:not(:last-child) {
    margin-bottom: 44px;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 24px;
  }

  & > div:nth-child(2) {
    flex-shrink: 0;
  }
}

.storyTitle {
  color: #061121;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 16px;

  @media (max-width: 992px) {
    font-size: 1.5rem;
  }
}

.storyTxt {
  color: #666366;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 400;
  width: 100%;
  margin-top: 10px;
}

.visionSection {
  margin: 50px 0;
  padding: 0 66px;

  @media (max-width: 992px) {
    padding: 0 16px;
  }

  &.bgPink {
    background: #fef8fe;
    border-radius: 10px;
    padding: 40px 66px;

    @media (max-width: 992px) {
      padding: 16px;
    }
  }
}

.visionSectionTitle {
  color: #061121;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 24px;

  @media (max-width: 992px) {
    font-size: 1.25rem;
  }
}

.visionContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.visionWrapper {
  width: 100%;
  border: 2px solid #cf28d4;
  background: #ffebff;
  padding: 16px;

  & img {
    margin-bottom: 16px;
  }
}
.visionTitle {
  color: #061121d8;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 16px;
}

.visionText {
  letter-spacing: 0.32px;
  color: #525868;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6;
}
.testimonial {
  margin-top: 50px;
  border-radius: 10px;
  background: #fef8fe;
}

.testimonialHeader {
  padding: 44px 66px 0;
  @media (max-width: 767px) {
    padding: 16px 16px 0;
  }
}

.testimonialBox {
  margin-top: 25px;
  gap: 20px;
}
.testWrapp {
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  border-radius: 1px;
  background: #ffffff;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;
}
.testBox2 {
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 15px;
}

.innrBox {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.testimonialTxt {
  color: #000000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}
.innrSubHeading {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
  color: #000000;
}
.innrSubTxt {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
}

.ourTeam {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.sectionHeader {
  border: 2px solid #cf28d4;
  border-radius: 100%;
  padding: 6px;
}
.sectionMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usernameTxt {
  font-size: 1.25rem;
  font-weight: bold;
  color: #000000;
  line-height: 1.4;
}

.userdesignationTxt {
  font-size: 1.25rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.4;
}

.sectionBottom {
  display: flex;
  gap: 8px;
}
.backgroundImg {
  top: 40%;
  left: 89%;
  composes: backgroundImg from './subscription.module.scss';
}
.backgroundImg2 {
  top: 100%;
  left: 3px;
  z-index: -1;
  composes: backgroundImg from './subscription.module.scss';
}
