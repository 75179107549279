.wrapper {
  height: 100%;
  display: flex;
  flex-shrink: 0;
}
.button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.popoverPanel {
  background: #402061;
  color: #fff;
  padding: 3px 8px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 5px;
  max-width: 220px;
  overflow-wrap: anywhere;
}
