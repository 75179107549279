$white: #ffffff;
$white-light: #dbdbdb;
$white-lighter: #ececec;
$primary: #d633db;
$primary-dark: #7c087f;
$primary-darker: #520054;
$pink-lighter: #d7b2d8;
$pink-light: #dba4dc;
$pink: #ff00a2;
$pink-dark: #cd3395;
$txt-color: #333333;
$black-light: #3b3b3b;
$black: #222222;
$black-dark: #0e0e0e;
$blue-dark: #061121;
$gray: #606060;
$gray-light: #757575;
$grey-lighter: #979797;

$input-border: #900794;

// breakpoints
$sm: 640px;
// => @media (min-width: 640px) { ... }
$md: 768px;
// => @media (min-width: 768px) { ... }
$lg: 1024px;
// => @media (min-width: 1024px) { ... }
$xl: 1280px;
// => @media (min-width: 1280px) { ... }
$xxl: 1536px;
// => @media (min-width: 1536px) { ... }

// spacing
$spacing-0: 0; //0px
$spacing-px: 1px; //1px
$spacing-0_5: 0.125rem; //2px
$spacing-1: 0.25rem; //4px
$spacing-1_5: 0.375rem; //6px
$spacing-2: 0.5rem; //8px
$spacing-2_5: 0.625rem; //10px
$spacing-3: 0.75rem; //12px
$spacing-3_5: 0.875rem; //14px
$spacing-4: 1rem; //16px
$spacing-5: 1.25rem; //20px
$spacing-6: 1.5rem; //24px
$spacing-7: 1.75rem; //28px
$spacing-8: 2rem; //32px
$spacing-9: 2.25rem; //36px
$spacing-10: 2.5rem; //40px
$spacing-11: 2.75rem; //44px
$spacing-12: 3rem; //48px
$spacing-14: 3.5rem; //56px
$spacing-16: 4rem; //64px
$spacing-20: 5rem; //80px
$spacing-24: 6rem; //96px
$spacing-28: 7rem; //112px
$spacing-32: 8rem; //128px
$spacing-36: 9rem; //144px
$spacing-40: 10rem; //160px
$spacing-44: 11rem; //176px
$spacing-48: 12rem; //192px
$spacing-52: 13rem; //208px
$spacing-56: 14rem; //224px
$spacing-60: 15rem; //240px
$spacing-64: 16rem; //256px
$spacing-72: 18rem; //288px
$spacing-80: 20rem; //320px
$spacing-96: 24rem; //384px

// border radius
$border-none: 0;
$border-sm: 0.125rem;
$border-md: 0.25rem;
$border-lg: 0.5rem;
$border-full: 9999px;
