@use 'assets/scss/variables' as v;

.sidebar {
  background-color: #402061;
  position: fixed;
  height: 100%;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  box-shadow: 0px 0px 20px #cc47cf;
}

.navbarList ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.hoverState {
  border: 0.5px solid #fff0ff;
  background: transparent
    radial-gradient(closest-side at 32% 22%, #a200a6 0%, #800383 100%);
  padding: 0px 10px;
}

.navLinks {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0.5px solid transparent;
  transition: all 0.3s ease-in;
  background: transparent;
}

.navLinks:hover,
.navLinks.active {
  border-color: #fff0ff;
  background: transparent
    radial-gradient(closest-side at 32% 22%, #a200a6 0%, #800383 100%);
}

.navbarBtm {
  margin-top: auto;
}

.navImg {
  height: 50px;
  width: 50px;
}

.navLogoImg {
  height: 50px;
}

.navbarBtm {
  margin-top: auto;
}

//=================================== sidebar css

// =================================page Container css
.pageContainer {
  width: calc(100% - 75px);
  margin-left: 75px;
  padding-bottom: 75px;
}

.dashboardTop {
  background: rgb(205 51 149 / 10%);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.transBg {
  position: absolute;
}

.cardtransBg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.innerDashboardContent {
  padding: 34px 30px;
  z-index: 1;

  @media (max-width: 992px) {
    padding: 24px 16px;
  }
}

.alertBox {
  color: #666666;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 600;
  border: 2px dashed #85078840;
  display: flex;
  gap: 15px;
  height: 49px;
  padding: 13px 0px;
  align-items: center;
  width: 100%;
  max-width: 1219px;

  @media (max-width: 992px) {
    font-size: 0.875rem;
  }
}

.spanBox {
  background: #8d079140;
  width: 10px;
  height: 49px;
}

.useGreeting {
  color: #19191c;
  display: flex;
  align-items: center;
  margin-top: 37px;
  gap: 7px;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 400;

  @media (max-width: 992px) {
    margin-top: 20px;
  }
}

.inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 915px;
  /* padding: 11px; */
}

.dropdownBtn {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  background: #7c287d;
  padding: 18px;
  width: 155px;
  flex-shrink: 0;
}

.itemImage {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.selectedItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selectOptions {
  max-height: 240px;
  width: calc(100% + 155px);
  background: v.$white;
  padding: 4px 0;
  z-index: 1;
  outline: none;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  overflow: auto;

  & > .selectOption {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 14px;
    font-size: 14px;
    line-height: 1.4;
    color: v.$txt-color;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 500;

    & > * {
      flex-shrink: 0;
    }

    & > .subText {
      font-weight: 400;
      opacity: 0.7;
    }

    &.active {
      background: rgba(v.$primary, 0.12);
    }
  }
}

.searchBox {
  margin-top: 13px;
}

.tagInput {
  width: 100%;
}

.searchbarInput {
  width: 100%;
  padding: 18px 22px;
  outline: none;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #1b1c3199;
  font-weight: 600;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.dropdownItemName {
  color: v.$white;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.searchModal {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  cursor: pointer;
}

.linkTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #cd3395;
  font-weight: 600;
}

.viewAllCate {
  background: transparent linear-gradient(109deg, #7c087f 0%, #a506aa 100%);
  width: 100%;
  max-width: 424px;
  display: flex;
  align-items: center;
  padding: 20px 10px;

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.categoryContainer {
  margin: 22px 16px 0 30px;
  display: flex;
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    margin: 16px;
  }
}

.categoryHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.categoryTxt {
  color: v.$white;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 0.75rem;
  }
}

.drImg {
  transform: rotate(270deg);
}

.expertCate {
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  padding: 18px 20px;
  border-radius: 1px;
  width: 100%;
}

.expertInner {
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.expertCallImg {
  flex-shrink: 0;
  & > img {
    height: 100px;
    width: 106px;
    object-fit: contain;
  }
}

.expList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 18px;
}

.guidedText {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
  color: #19191c;
}

.expInnerList {
  width: 100%;
  max-width: 370px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1600px) {
    max-width: 290px;
  }

  @media (max-width: 1400px) {
    max-width: 250px;
  }
}

.connctBtn {
  background: transparent linear-gradient(113deg, #7c087f 0%, #a506aa 100%);
  width: 100%;
  padding: 15px 19px;
  font-size: 0.875rem;
  color: white;
  line-height: 1.4;
  font-weight: 600;
  border: none;
  outline: none;
}

.guestUser {
  margin-top: 30px;
  margin-left: 30px;
  position: relative;
}

.blurBg {
  width: 100%;
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #cccccce0 51%, #ffffff 100%);
  backdrop-filter: blur(5px);
  -webkit-filter: blur(5px);
}

.subscriptionBox {
  background: #3a113b;
  position: sticky;
  padding: 30px 25px;
  width: 100%;
  border-radius: 3px;
  margin: auto;
  z-index: 2;
  bottom: 0px;
  max-width: 92%;
}

.subsTxt {
  display: flex;
  gap: 16px;
  width: 100%;
}

.subscriberHeader {
  display: flex;
  justify-content: space-between;
}

.premiumTxt {
  color: v.$white;
  font-weight: 600;
  line-height: 1.4;
  font-size: 1.25rem;
}

.crossImg img {
  cursor: pointer;
}

.subscriptionDetail {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.featurePoints {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

span.featurTxt {
  font-size: 1rem;
  font-weight: 400;
  color: #beb1bf;
  line-height: 1.4;
}

.subscribeButtons {
  display: flex;
  gap: 15px;
}

.scheduleBtn {
  border: 1px solid #ffffff;
  color: #fff;
  padding: 17px 20px;
  text-align: center;
}

.subscribenowBtn {
  background: transparent linear-gradient(106deg, #7c087f 0%, #a506aa 100%);
  color: #fff;
  padding: 17px 30px;
}

.rowCard {
  display: flex;
  gap: 10px;
  margin-top: 24px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    // overflow: auto;
  }
}

.cardHeader {
  border: 1px solid #ff00a233;
  background: #ffffff;
  padding: 10px;
  width: 100%;
  position: relative;
}

.cardImage {
  width: 100%;
}

.playBtnSection {
  position: absolute;
  left: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(-50%, -100%);
}

.playBtn {
  width: 100%;
  max-width: 60px;
}

.cardTxt {
  font-size: 1.125rem;
  color: #19191c;
  line-height: 1.4;
  font-weight: 800;
  margin-top: 9px;
  margin-left: 7px;
}

.expDate {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  color: #8c8c8d;
  margin-top: 7px;
  margin-left: 7px;
}

.campaignBox {
  margin: 39px 16px 0 35px;
  padding-bottom: 46px;

  @media (max-width: 992px) {
    margin: 24px 16px 0;
  }
}

.boxTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #24263a;
  line-height: 1.4;
}

.boxContent {
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  border-radius: 1px;
  margin-top: 20px;
  padding: 38px 35px;

  @media (max-width: 992px) {
    margin-top: 16px;
    padding: 16px;
  }
}

.boxLiner {
  display: flex;
  gap: 28px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.totalCount {
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  border-radius: 1px;
  padding: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

h2.countTitle {
  color: #7c087f;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 600;
}

p.countNumber {
  font-size: 1.875rem;
  color: #ff00a2;
  font-weight: 600;
}

.performanceBenchMark {
  margin: 44px 0 30px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    margin: 28px 0 16px;
  }
}

.benchmarkSectionTitle {
  color: #616e7c;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
}

.benchmarkSectionView {
  max-width: 170px;
  width: 100%;
}

.selctMenu {
  background: none;
  border-bottom: 2px solid #ff00a2;
  width: 100%;
  outline: none;
}

.graphImg {
  width: 100%;
}

// ==================== campaign popup =====================
.campaignContainer {
  z-index: 1;
  padding: 50px;
}

.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.campaignHeading {
  display: flex;
  margin-top: 23px;
  gap: 15px;
  align-items: center;
}

.campignTxt {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #19191c;
  font-weight: 600;
}

.categoryButtons {
  display: flex;
  gap: 5px;
  z-index: 1;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.influencerCateBtn {
  background: rgba(255, 255, 255, 0.2509803922);
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #19191c;
  min-width: 135px;
  cursor: pointer;
  text-transform: capitalize;

  @media (max-width: 992px) {
    padding: 15px 8px;
    font-size: 0.875rem;
  }

  @media (max-width: 641px) {
    flex-grow: 1;
  }
}

.whiteActiveBtn {
  background: #fff;
}

.billingTableContainer {
  overflow: auto;

  margin: 20px 0px;
}

.billingTable {
  width: 100%;
  border-collapse: collapse;
}

// .billingTable tr:nth-child(even) {
//   background: rgba(255, 0, 162, 0.05);
// }

.billingTable tr th {
  padding: 16px 0px 16px 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #718096;
  text-align: left;
  white-space: nowrap;
}

.billingTable tr td {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #14171f;
  padding: 16px 0px 16px 50px;

  &.capitalize {
    text-transform: capitalize;
  }
}

.billingFooter {
  border: 2px solid #fee7ff;
  margin: 0 25px;
  display: flex;
  justify-content: space-between;
}

.billingFooter p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #14171f;
  padding: 16px 16px 16px 25px;
}

// ============================= profile Information ==================
.personlInfoCont {
  margin-top: 24px;
  border: 2px dashed rgba(133, 7, 136, 0.2509803922);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 30px;
  position: relative;
}

.innrBlock1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.subblock1 {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;

  @media (max-width: 992px) {
    gap: 6px;
  }
}

.perTxt {
  font-size: 1.125rem;
  line-height: 1.4;
  color: #3b3b3b;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 1rem;
  }
}

.editIcon {
  cursor: pointer;
}

.sideBar {
  position: absolute;
  left: 0;
  height: 100%;
  background: rgba(141, 7, 145, 0.2509803922);
  width: 10px;
}

.innrBlock2 {
  display: flex;
  margin: 12px 0px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.profileForm {
  width: 100%;
  max-width: 570px;
}

.headingTxt {
  composes: headingTxt from './profile-dashboard.module.scss';
}

.addressContainer {
  composes: addressContainer from './profile-dashboard.module.scss';
  justify-content: flex-start;
  gap: 8px;
}

.addButton {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1px 10px;
  color: #7c087f;
  border: 1px solid #7c087f;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
}

.detailInner {
  composes: detailInner from './profile-dashboard.module.scss';
}

.categoryBlock {
  composes: categoryBlock from './profile-dashboard.module.scss';
}

.categoryText {
  background: #fff;
  composes: categoryText from './profile-dashboard.module.scss';
}

.subTxt {
  margin-bottom: 16px;
  composes: subTxt from './profile-dashboard.module.scss';
}

.userStatus {
  composes: userStatus from './profile-dashboard.module.scss';
}

.socialPlatformWrapper {
  composes: socialPlatformWrapper from './profile-dashboard.module.scss';
}

.socialPlatform {
  composes: socialPlatform from './profile-dashboard.module.scss';
}

.socialImage {
  composes: socialImage from './profile-dashboard.module.scss';
}

.socialTxt {
  composes: socialTxt from './profile-dashboard.module.scss';
}

.profilePhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #7c087f;
}

.detailsection {
  display: flex;
  gap: 40px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.labelTxt {
  composes: labelTxt from './profile-dashboard.module.scss';
}

// ===================== Call Popover =================================

.callBtn {
  position: fixed;
  bottom: 34px;
  right: 34px;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  border-radius: 50%;
  z-index: 99;
}

.callPopoverPanel {
  width: 350px;
  background: white;
  box-shadow: 0px 20px 120px #1b213a38;
  margin: 0 0 0 34px;
  padding: 24px;

  @media (max-width: 992px) {
    width: 270px;
  }

  & .heading {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #19191c;
    margin-bottom: 20px;
  }
}
