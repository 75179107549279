@use 'assets/scss/variables' as v;

.contained {
  position: relative;
  cursor: pointer;

  color: v.$white;
  background: transparent linear-gradient(96deg, #7c087f 0%, #a506aa 100%) 0% 0%
    no-repeat padding-box;

  .buttonText {
    display: inline-block;
  }

  &.loading {
    .buttonText {
      color: transparent;
    }
    .loader {
      display: grid;
      place-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;
    }
  }
}

.outlined {
  composes: contained;
  border: 1px solid v.$primary-dark;
  color: v.$primary-dark;
  border-radius: 0;
  background: none;

  &.loading {
    .loader {
      color: v.$primary-dark;
    }
  }
}

.text {
  composes: contained;
  color: v.$primary-dark;
  border-radius: 0;
  border: none;
  background: none;

  &.loading {
    .loader {
      color: v.$primary-dark;
    }
  }
}

.small {
  padding: 6px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}

.medium {
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.large {
  padding: 14px 28px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
}

.fullWidth {
  width: 100%;
}
