@use 'assets/scss/variables' as v;

.menuBtn {
  padding: 8px;
}

.menuItems {
  background: v.$white;
  box-shadow: inset 0px 0px 5px #fac2fc40, 0px 0px 10px #fac2fc;
  padding: 4px 0;
  min-width: 170px;
}

.menuItem {
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  & > svg {
    width: 16px;
    height: 16px;
  }

  &.active {
    background: #ff00a212;
  }

  &.disabled {
    opacity: 0.7;
    cursor: default;
  }
}
