.dialog {
  position: fixed;
  z-index: 1300;
  inset: 0px;
}

.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(53, 60, 89, 0.5);
  z-index: -1;
  // backdrop-filter: blur(4px);
}

.dialogContainer {
  height: 100%;
  outline: 0px;
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.topLeft {
  justify-content: flex-start;
  align-items: flex-start;
}

.topCenter {
  justify-content: center;
  align-items: flex-start;
}
.topRight {
  justify-content: flex-end;
  align-items: flex-start;
}

.centerRight {
  justify-content: flex-start;
  align-items: center;
}
.centerLeft {
  justify-content: flex-end;
  align-items: center;
}

.bottomLeft {
  justify-content: flex-start;
  align-items: flex-end;
}

.bottomCenter {
  justify-content: center;
  align-items: flex-end;
}
.bottomRight {
  justify-content: flex-end;
  align-items: flex-end;
}

.dialogPanel {
  background: #ffffff;
  box-shadow: 0px 20px 120px #1b213a66;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #19191c;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 32px;
  max-height: calc(100vh - 64px);

  &.transparent {
    background: transparent;
    box-shadow: none;
  }

  &.rounded {
    border-radius: 16px;
  }

  &.fullWidth {
    width: calc(100% - 64px);
  }

  &.fullHeight {
    height: calc(100% - 64px);
  }

  &.xs {
    max-width: 500px;
  }
  &.sm {
    max-width: 650px;
  }
  &.md {
    max-width: 900px;
  }
  &.lg {
    max-width: 1200px;
  }
  &.xl {
    max-width: 1536px;
  }
}

.headerContainer {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  gap: 24px;
}

.dialogTitle {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0px;
}

.closeIcon {
  flex: 0 0 auto;
}

.dialogContent {
  padding: 0 25px 20px;
  flex: 1 1 auto;
  overflow-y: auto;
}

.alertDialogContent {
  composes: dialogContent;
  color: #616e7c;
  letter-spacing: 0.1px;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 25px 18px;
  flex: 0 0 auto;
  gap: 20px;
}

// transiotions

.enter {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.leave {
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 100;
}

.view {
  composes: opacity1;
  transform: translateY(0px);
}

.hidden {
  composes: opacity0;
  transform: translateY(50px);
}
