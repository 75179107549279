.payments {
  position: relative;
  overflow-x: hidden;
}
.headingContainer {
  background: #7c087f;
  padding: 16px 0px;
  @media (min-width: 1024px) {
    padding: 30px 0px;
  }
}
.heading {
  text-align: center;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 600;
  @media (min-width: 1024px) {
    font-size: 2.25rem;
  }
}
.container {
  composes: container from '../common.module.scss';
  min-height: 700px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.topRightDots,
.bottomLeftDots {
  position: absolute;
  display: none;
  z-index: -1;
  @media (min-width: 1024px) {
    display: block;
  }
}
.topRightDots {
  top: 162px;
  right: -110px;
}
.bottomLeftDots {
  bottom: 40px;
  left: -50px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;

  @media (min-width: 1024px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
  }
}
.column {
  flex-basis: 100%;
  flex: 1;
  max-width: 358px;
  width: 100%;
}

.sectionHeading {
  color: #4e3883;
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 0.87px;
}

.orderTable {
  width: 100%;
  margin-top: 30px;
  color: #393e46;

  & > thead th,
  & > tbody td {
    border-bottom: 1px solid #d2d2d2;
  }

  & > tbody td,
  & > tfoot td,
  & > tfoot th {
    padding: 10px 0;
  }

  & > thead th {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
    padding: 0 0 10px;
  }

  & > tbody td {
    font-size: 1rem;
  }

  & > tbody > .gstRow > td {
    font-size: 0.875rem;

    & > span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  & > tfoot > tr {
    & > th,
    & > td {
      font-size: 1.125rem;
      font-weight: 600;
      letter-spacing: 0;
    }

    & > td {
      letter-spacing: 0.58px;
    }
  }

  & .left {
    text-align: left;
  }
  & .right {
    text-align: right;
  }
}

.paymentForm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  & * {
    outline-offset: 3px;
  }
}
.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;

  & > * {
    width: 50%;
  }
}
.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input {
  width: 100%;
  display: inline-block;
  padding: 12px 6px 12px 16px;
  border: 1px solid #d2d2d2;
  color: #707070;
  background: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &[data-invalid] {
    border: 1px solid #f64242;
  }

  &[data-disabled],
  &:disabled {
    background: #f2f2f2;
    opacity: 1;
  }
}
.selectWrapper {
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 18px;
    right: 16px;
    width: 10px;
    height: 10px;
    border: 2px solid #757575;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
}
.label {
  color: #393e46;
  font-weight: 500;
  font-size: 0.8125rem;
  letter-spacing: 0.16px;
}
.error {
  color: #f64242;
  margin-top: 4px;
  font-size: 0.8125rem;
}
.radioInputContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.termsLabel {
  font-size: 0.6875rem;
  color: #393e46;
  letter-spacing: 0px;

  & > span,
  &[data-invalid] {
    color: #f64242;
  }
}
.privacyNote {
  color: #393e46;
  font-size: 0.8125rem;
  letter-spacing: 0.16px;
  text-align: left;

  & > a {
    text-decoration: underline;
  }
}

.payButton {
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0px;
  font-weight: 500;
  color: #ffffff;
  padding: 14px;
  text-transform: capitalize;
  background: transparent linear-gradient(97deg, #7c087f 0%, #a506aa 100%) 0% 0%
    no-repeat padding-box;

  &:disabled {
    opacity: 1;
    background: #bfbfbf;
    cursor: not-allowed;
  }
}

.statusContainer {
  padding: 24px;
  position: relative;

  & > .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .playerWrapper {
    margin: 16px auto;
    width: 50%;
    max-width: 212px;
    max-height: 212px;
    position: relative;
    @media (min-width: 768px) {
      margin: 30px auto;
    }
  }

  .statusHeading {
    text-align: center;
    color: #900794;
    letter-spacing: 0px;
    font-size: 1.5rem;
    font-weight: 700;

    @media (min-width: 640px) {
      font-size: 1.75rem;
    }

    @media (min-width: 768px) {
      font-size: 2.125rem;
    }
  }

  .statusDescription {
    color: #3e3e3e;
    text-align: center;
    font-size: 0.875rem;
    margin: 10px auto;

    @media (min-width: 640px) {
      margin: 20px auto;
      font-size: 1rem;
    }

    @media (min-width: 768px) {
      margin: 30px auto;
      font-size: 1.25rem;
    }
  }

  .paymentDetails {
    color: #3e3e3e;
    text-align: center;
    font-size: 0.875rem;
    margin: 10px auto;

    @media (min-width: 640px) {
      margin: 20px auto;
      font-size: 1rem;
    }

    @media (min-width: 768px) {
      margin: 30px auto;
    }
  }
}
