html {
  font-family: Poppins, -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu,
    Cantarell, Helvetica Neue, sans-serif;
}

* {
  outline-color: #ff00a2;
}

button:disabled {
  opacity: 0.6;
}

.fade-in {
  animation: 0.3s animate-fade-in ease;
}

.fade-out {
  animation: 0.15s animate-fade-out ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade-in,
  .fade-out {
    animation: none !important;
  }
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
