@use 'assets/scss/variables' as v;
.footer {
  background: v.$blue-dark;
  margin-top: 60px;
}
.footerTop {
  padding: 60px 90px;
  display: flex;
  // background: v.$blue-dark;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    padding: 60px 18px;
    gap: 30px;
  }
  .footerNav {
    h2 {
      color: v.$white;
      font-size: 1em;
      line-height: 1.5;
      font-weight: 500;
    }
    nav {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 22px;
      a {
        color: v.$white;
        font-size: 1em;
        line-height: 1.3;
        text-decoration: none;
        font-weight: 200;
        position: relative;
        margin-left: 26px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          position: absolute;
          background: url(../../../assets/images/bullet.svg), no-repeat;
          left: -26px;
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}
.footerLeft {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  max-width: 420px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  .pTitle {
    color: v.$white;
    opacity: 0.85;
    font-size: 1em;
    line-height: 1.7;
  }
  .socialLinks {
    margin-top: 35px;
    display: flex;
    gap: 9px;
  }
}
.footerBottom {
  background: v.$primary-dark;
  padding: 25px 0px;
  .pTitle {
    text-align: center;
    color: v.$white;
    font-size: 0.75em;
    line-height: 1.4;
    font-weight: 400;
  }
}

.footerHeader {
  @media (max-width: 992px) {
    padding: 0 30px;
  }
}
.joinTxt {
  font-size: 53px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  max-width: 660px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 72px;
  @media (max-width: 992px) {
    font-size: 38px;
    padding-top: 30px;
  }
}
.addressSubscribe {
  position: relative;
}
.emailInput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 36px auto 0;
  padding: 20px 160px 20px 20px;
  width: 100%;
  max-width: 493px;
  border-radius: 5px;
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 12px 130px 12px 12px;
  }
}
.subBtn {
  position: absolute;
  background: linear-gradient(180deg, #7c087f, #eb55f0, #e83aec);
  top: 0;
  right: 0;
  left: 361px;
  width: 100%;
  margin: auto;
  max-width: 135px;
  padding: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 992px) {
    left: auto;
    padding: 12px 90px 12px 12px;
    max-width: 100px;
  }
}

.pinkBg {
  background: #ff00a2;
}
