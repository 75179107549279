.campignBreadcrumbs {
  composes: campignBreadcrumbs from './search.module.scss';
}

.navTxt {
  composes: navTxt from './search.module.scss';
}

.rightArrow {
  composes: rightArrow from './search.module.scss';
}

.navSecndTxt {
  composes: navSecndTxt from './search.module.scss';
}

.quotationContainer {
  padding: 50px;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.displayNone {
  display: none;
}

.quotationHeader {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.quoHeading {
  color: #19191c;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.quoSubHeaidng {
  color: #7f60ff;
  font-size: 1.25rem;
}

.downloadQuotBtn {
  display: flex;
  gap: 6px;
  background: transparent linear-gradient(106deg, #7c087f 0%, #a506aa 100%);
  padding: 18px 22px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
}

.quoSub {
  display: flex;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.quotationDetail {
  width: 100%;
}

.detailHeader {
  display: flex;
  margin-top: 23px;
  border-bottom: 3px solid #0000000f;
  padding-bottom: 8px;
}

.detailHeading {
  letter-spacing: 0.87px;
  color: #4e3883;
  font-size: 1.188rem;
  line-height: 1.4;
  font-weight: 600;
  width: 100%;
}

.detailInnerContent {
  display: flex;
  margin-top: 23px;
}

.subPart {
  width: 100%;
}

.labelContainer {
  display: flex;
  gap: 5px;
  margin-bottom: 9px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.title {
  color: #757575;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.content {
  color: #3b3b3b;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.addressContent {
  composes: content;
  max-width: 290px;
}

.platformIconsWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  & > * {
    flex-shrink: 0;
  }

  & img {
    width: 18px;
    height: 18px;
  }
}

.tableHeading {
  color: #3b3b3b;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
}

.tableContainer {
  overflow: auto;
  box-shadow: 0px 2px 10px #ff00a21a;
  border: 1px solid #ff00a233;
  margin: 20px 0px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th {
    padding: 15px 0 15px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #718096;
    text-align: left;
    white-space: nowrap;

    &:last-child {
      padding-right: 15px;
    }
  }

  & > tbody {
    & > tr:nth-child(odd),
    & > tr.nestedRow {
      background: rgba(255, 0, 162, 0.05);
    }

    & > tr.collapsibleRow {
      background: transparent;
    }
  }

  & td {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    color: #14171f;
    padding: 15px 0px 15px 20px;
  }

  & tr.dense td {
    padding: 8px 0 8px 20px;
  }

  & > tfoot {
    border-top: 1px solid #ff00a233;

    & td {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.profileDiv {
  display: flex;
  align-items: center;
  gap: 8px;
}

.boldTableText {
  font-size: 16px;
  font-weight: 600;
}

.actionButton {
  padding: 10px 10px 10px 0;
}

.downIcon {
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}

.upIcon {
  composes: downIcon;
  transform: rotate(180deg);
}

.totalAmntCal {
  display: flex;
  justify-content: space-between;
  background: #402061;
  padding: 20px 25px;
  margin-top: 20px;
}

.amnTitle {
  color: #ffffff;
  font-size: 0.938rem;
  font-weight: 600;
}

.price {
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
}

.purchaseBtns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.paymntCntainer {
  display: flex;
  gap: 56px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.uploadBtn {
  color: #7e0881;
  border: 1px solid #7c087f;
  padding: 17px 25px;
  font-size: 0.75rem;
  font-weight: 600;
}

.proceedBtn {
  color: #ffffff;
  background: transparent linear-gradient(106deg, #7c087f 0%, #a506aa 100%);
  padding: 17px 36px;
  font-size: 0.75rem;
  font-weight: 600;
}

.paymntLeft {
  width: 100%;
  max-width: 522px;
}

.paymntRight {
  width: 100%;
  max-width: 613px;
}

.paymentRightBg {
  background: rgba(205, 51, 149, 0.1);
}

.summaryDetail {
  margin-top: 0;
  padding: 15px 26px;
  border-bottom: 3px solid #0000000f;
}

.innerTableItems {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #f8f9fa;
  padding: 18px;
}

.itemsTitle {
  color: #3b3b3b;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.6;
  margin-bottom: 20px;
}

.tableItems td {
  font-size: 1rem;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 1.5;
  padding-bottom: 20px;
}

.total {
  padding: 20px 26px;
  display: flex;
  justify-content: space-between;
}

.genderBox {
  flex-wrap: wrap;
  composes: genderBox from './popup.module.scss';
}

.innrTnput {
  composes: innrTnput from './popup.module.scss';
}

.inputContainer {
  font-weight: 500;
  color: #14171f;
  composes: inputContainer from './popup.module.scss';
}

.innrSubIput {
  composes: innrSubIput from './popup.module.scss';
}

.radioField {
  composes: radioField from './popup.module.scss';
}

.paymentWrapper {
  margin-top: 26px;
  border: 1px solid #ff00a233;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.fullPaymnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.paymntTitle {
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 7px;
}

.paymntPrice {
  color: #3b3b3b;
  font-size: 1.375rem;
  font-weight: 800;
  line-height: 1.4;
}

.payBtnWrapper {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
}

.payBtn {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 17px 48px;
  background: transparent linear-gradient(108deg, #7c087f 0%, #a506aa 100%);
}

.paymentStatus {
  display: flex;
  gap: 6px;
  align-items: center;
  color: #2b7614;
  font-weight: 600;

  &.processing {
    color: #edac3c;
  }
}
