@use 'assets/scss/variables' as v;
.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.error {
  color: #f64242;
  margin-top: 6px;
  font-size: 14px;
}

// transiotions

.enter {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.leave {
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 100;
}

.view {
  composes: opacity1;
  transform: translateY(0px);
}

.hidden {
  composes: opacity0;
  transform: translateY(50px);
}
