@use 'assets/scss/variables' as v;
.container {
  composes: container from '../common.module.scss';
}

.headingContainer {
  background: #7c087f;
  padding: 31px 0px;
}
.headingTxt {
  text-wrap: balance;
  text-align: center;
  font-size: 2.25rem;
  line-height: 1.4;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 992px) {
    font-size: 1.5rem;
  }
}
.detailOuterContainer {
  background: #fef8fe;
  border-radius: 10px;
  padding: 37px 66px;
  margin-top: 24px;
  @media (max-width: 992px) {
    padding: 10px 20px;
  }
  .detailContainer {
    font-size: 1.125rem;
    color: #00000099;
    line-height: 1.5;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }
    h1 {
      font-size: 2.25rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.55rem;
    }
    h5 {
      font-size: 1.35rem;
    }
    h6 {
      font-size: 1.25rem;
    }

    p {
      margin-bottom: 25px;
      margin-top: 25px;
    }

    a {
      color: #000000e6;

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    ul,
    ol {
      margin-block-start: 25px;
      margin-block-end: 25px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 6px;
      }
    }
  }
}

.dotBg {
  position: absolute;
  right: -95px;
  z-index: -1;
  top: 35px;
  @media (max-width: 1200px) {
    display: none;
  }
}
.backgr {
  right: 7px;
}
.dotBg2 {
  position: absolute;
  left: -125px;
  top: 17%;
  z-index: -1;
  @media (max-width: 1200px) {
    display: none;
  }
}
.backgr2 {
  left: 8px;
  top: 78%;
}
// ================================= contact us screen
.contactContainer {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding: 50px 16px 70px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.contctLeft {
  position: relative;
  max-width: 569px;
  width: 100%;
}
.contactInnrLeft {
  border: 2px solid #cf28d4;
  padding: 48px 16px 80px;
  background: #ffebff;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: v.$lg) {
    padding: 60px;
  }
}
.cntctInnrDetail {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}
.dirBtn {
  background: #d61fdb;
  display: inline-flex;
  gap: 6px;
  border-radius: 11px;
  padding: 5px 13px;
  margin-top: 24px;
  letter-spacing: 0.36px;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  align-items: center;
}
.subTitle {
  color: #061121;
  font-size: 1.625rem;
  line-height: 1.4;
  font-weight: 400;
}
.subDetail {
  letter-spacing: 0.32px;
  color: #525868;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 18px;
}
img.cntctImage1 {
  position: absolute;
  right: -45px;
  top: 16px;
  max-width: 170px;
  width: 100%;

  @media (max-width: 992px) {
    right: 20px;
    top: -20px;
  }
}

img.cntctImage2 {
  position: absolute;
  bottom: -45px;
  right: 32px;

  @media (min-width: v.$lg) {
    right: 60px;
  }
}
.contctHeading {
  font-size: 2.063rem;
  line-height: 1.4;
  font-weight: 400;
  color: #061121;
}

.contctDetail {
  letter-spacing: 0.32px;
  color: #525868;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
}
.contctRight {
  width: 100%;
  max-width: 529px;
}
.contctForm {
  display: grid;
  @media (min-width: v.$md) {
    grid-template-columns: 1fr;
  }
  gap: 50px;
}

.submitBtn {
  composes: submitBtn from '../pages/profile.module.scss';
}
.saveBtn {
  composes: saveBtn from '../pages/profile.module.scss';
}

.buttonTxt {
  composes: buttonTxt from '../pages/profile.module.scss';
}
.mx {
  margin-top: 46px;
}
.buttonWidth {
  max-width: 166px;
}
.emCntainer {
  display: flex;
  gap: 31px;
}
.cntctFormTitle {
  letter-spacing: 0.36px;
  color: #061121;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
}
.formControl {
  max-width: 100%;
  composes: formControl from '../../../components/common/form/form.module.scss';
}
.inputWrapper {
  composes: inputWrapper from '../../../components/common/form/form.module.scss';
}
.label {
  font-size: 0.688rem;
  composes: label from '../../../components/common/form/form.module.scss';
}
.input {
  font-weight: 400 !important;
  composes: input from '../../../components/common/form/form.module.scss';
}
// ======================== Faq Screen ====================

.faqContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
}
.faQuestn {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;

  @media (min-width: 640px) {
    font-size: 1.25rem;
  }
}
.faqAnswer {
  color: #a8a8a8;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 30px 37px;
  @media (min-width: 640px) {
    font-size: 1.125rem;
  }
}
.rightArrow {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;

  &.open {
    transform: rotate(180deg);
  }
}

.faqOuter {
  border: 2px solid transparent;
  border-radius: 1px;
  margin-top: 24px;
  background: #d633db0d;
  transition: all 0.3s ease;
}
.activeContainer {
  border: 2px solid #d633db;
  background: #ffffff;

  & .closerBtn {
    padding: 37px 30px 16px;
  }
}
.closerBtn {
  width: 100%;
  padding: 37px 30px 32px;
  transition: all 0.2s ease;
}
.backgBg {
  top: 100%;
}

//  ========================= case studies ============================

.caseStudiesWrapper {
  composes: caseStudiesWrapper from './landing-homepage.module.scss';
  margin-top: 30px;
}

.caseStudyVideo {
  composes: caseStudyVideo from './landing-homepage.module.scss';
}
