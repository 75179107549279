@use 'assets/scss/variables' as v;

.profileDashboard {
  display: flex;

  // height: 120vh;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    height: auto;
  }
}

.rightcontainer {
  display: flex;
  justify-content: space-between;
  // width: 23%;
  gap: 16px;
  margin-right: 16px;
}

.contractBtn {
  border: 1px solid #7c087f;
  outline: none;
  color: #7c087f;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  padding: 8px 15px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.contractBtn img {
  width: 16px;
}

.myProfileColumn {
  border-right: 1px solid #d7b2d8;
  padding: 25px;
  width: 100%;
  max-width: 350px;
  min-height: 100vh;

  @media (max-width: 992px) {
    max-width: 100%;
    min-height: auto;
  }
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .addButton {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 1px 10px;
    color: #7c087f;
    border: 1px solid #7c087f;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50px;
  }
}

.addressContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 6px;

  & > button {
    flex-shrink: 0;
    margin-top: 5px;
  }
}

.profileTxt {
  color: #19191c;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.editBtn {
  background: transparent linear-gradient(117deg, #7c087f 0%, #a506aa 100%);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 7px 17px;
  outline: none;
}

.profileInformation {
  // padding-top: 25px;
}

.headingTxt {
  color: #3b3b3b;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px 0px 10px;
}

.detailInner {
  margin-bottom: 10px;
}

.labelTxt {
  font-size: 0.875rem;
  font-weight: 400;
  color: #757575;
  line-height: 1.4;
  margin-bottom: 5px;
}

.subTxt {
  font-size: 0.875rem;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 1.4;
  margin-top: 5px;
}

.categoryBlock {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.categoryText {
  background: #f1f4f8;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 0.875rem;
  color: #3e4c59;
  font-weight: 400;
  line-height: 1.4;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gstFileUpload {
  border-bottom: 1px solid #dba4dc;
  padding-bottom: 15px;
  display: flex;
  gap: 6px;
}

.panFileUpload {
  padding-bottom: 15px;
  gap: 6px;
}

.gstFileUpload img {
  cursor: pointer;
}

.gstJpgFile {
  font-size: 1rem;
  color: #979797;
  line-height: 1.4;
  font-weight: 400;
  word-break: break-word;
}

// ====================================== second column css
.profileSettingcolumn {
  width: 100%;
}

.settingborder {
  border-bottom: 1px solid #d7b2d8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 14px;
    padding: 10px 25px;
  }
}

.settingList {
  display: flex;
  gap: 25px;
  padding: 25px 25px 0px 25px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    padding: 0px;
  }
}

.listTerm {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #616e7c;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.activeList {
  color: #d633db;
  // border-bottom: 2px solid #d633db;
  padding-bottom: 16px;
  font-weight: 600;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border: 1px solid;
    width: 100%;
  }
}

.logoutBtn {
  padding-right: 25px;
}

.profileLogoutBtn {
  background: transparent linear-gradient(105deg, #7c087f 0%, #a506aa 100%);
  color: #fff;
  display: flex;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
}

.settingCounter {
  padding: 25px;
}

.notifyHeader {
  display: flex;
  gap: 15px;
}

.notifyTxt {
  font-size: 1.125rem;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 1.4;
}

.notificationBtns {
  margin-top: 15px;
  display: flex;
}

.preferenceTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #3b3b3b;
  font-weight: 600;
  width: 100%;
  max-width: 250px;
}

.disableTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 15px 0px;
  color: #757575;
  font-weight: 400;
}

.disableBtn {
  border: 1px solid #7c087f;
  outline: none;
  color: #7c087f;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  padding: 5px 15px;
  width: 120px;
}

.subscriptionBoxContainer {
  padding: 25px;
}

.subscriptionTxt {
  font-size: 1rem;
  color: #3b3b3b;
  font-weight: 600;
  line-height: 1.4;
  padding-bottom: 8px;
  padding-top: 20px;
}

.upgradePlanContent {
  border: 1px solid #fb6eff;
  border-radius: 4px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 772px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

.planContainer {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.plnnrImg {
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 767px) {
    margin: auto;
  }
}

.block1 {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.subHeadingText {
  letter-spacing: 0.15px;
  color: #3b3b3b;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
}

.plannerTxt {
  color: #01b000;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
}

.expiredPlanTxt {
  text-decoration-line: line-through;
  color: #db0a10;
}

.block2 {
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.invoiceTxt {
  color: #757575;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.divideLine {
  border-left: 1px solid #707070;
  height: 12px;
}

.block3 {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.renewBtn {
  border: 1px solid #7c087f;
  color: #7c087f;
  outline: none;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
  padding: 12px 43px;
}

.upgradeBtn {
  background: transparent linear-gradient(107deg, #7c087f 0%, #a506aa 100%);
  color: #ffffff;
  padding: 12px 22px;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
  outline: none;
  white-space: nowrap;
}

.billingTableContainer {
  overflow: auto;
}

.billingTable {
  width: 100%;
}

.billingTable th {
  padding: 16px 0px 16px 25px;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.4;
  color: #19191c;
  text-align: left;
  white-space: nowrap;
}

.billingTable tr:nth-child(even) {
  background: rgb(255 0 162 / 4%);
}

.billingTable td {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
  color: #8f8b90;
  padding: 25px 0px 25px 25px;
}

.billingTable .amntText {
  color: #19191c;
}

/* The chat window container base */
.chatWindowContainerBase {
  display: flex;
  flex-direction: column;
  // justify-content: center;
}

/* The chat window container */
.chatWindowContainer {
  display: flex;
  flex-direction: column-reverse;
  background-color: #f1f1f1;
  margin-top: 10px;
  border-radius: 8px;
  height: 60vh;
  // padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #0b141a;
  overflow: auto;
  position: relative;
}

/* Style for the close button */
.closeButton {
  align-self: flex-end;
  color: #f1f1f1;
  background: #d13a2d;
  background-color: #d13a2d;
  font-size: 18px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  line-height: 20%;
  cursor: pointer;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.closeButton:hover {
  background-color: #ac2f24;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.4);
}

.closeButton:active {
  background-color: #800000;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Chat window content styling */
.chatWindow {
  padding: 0px;
  background-color: #0b141a;
  /* Add any other styles for your chat window */
}

/* Chat messages area */
.chatMessages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #0b141a;
  // height: 20px;
  // transform: translateZ(0); /* fixes a bug in Safari iOS where the scroller doesn't update */
}

/* Chat messages styles */
.messageSent {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  font-size: 15px;
  max-width: 80%;
  word-wrap: break-word;
  background-color: #00a889;
  margin-left: auto;
}

.messageReceived {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  font-size: 15px;
  max-width: 80%;
  word-wrap: break-word;
  background-color: #506d7f;
}

.messageReceivedUsername {
  color: #b4f7ff;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.messageTS {
  font-size: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.messageTSSent {
  font-size: 10px;
  text-align: right;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

/* Chat input area */
.chatInput {
  display: flex;
  padding: 10px;
  background-color: #fafafa;
  border-top: 1px solid #ddd;
}

.chatInput input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  margin-right: 10px;
}

.chatInput button {
  padding: 10px 20px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chatInput button:hover {
  background-color: #005fa3;
}

.downloadedTxt {
  color: #7c087f !important;
  font-weight: 600;
  line-height: 1.4;
}

// ============================================
.containerHoverlay {
  background-color: rgba(0, 0, 0, 0.702);
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 0 10px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.customInner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.container_content {
  background: #fff;
  box-shadow: 0px 0px 14px rgb(204 71 207/ 0.7);
  height: auto;
  overflow: auto;
  padding: 40px 30px;
  position: relative;
  width: 778px;
}

.popupTitle {
  color: #3b3b3b;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
}

.comparePlan {
  display: flex;
  gap: 20px;
  margin-top: 29px;
  justify-content: center;
}

.upgradeInputField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputTitle {
  color: #757575;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
}

.inputContainer {
  border-bottom: 3px solid #ff00a2;
  outline: none;
  margin-top: 4px;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
  color: #3b3b3b;
}

.upgradeBlock {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.blockLabel1 {
  border-radius: 1px;
  background: rgba(214, 51, 219, 0.0509803922);
  padding: 20px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.headingTxt {
  composes: headingTxt from './subscription.module.scss';
}

.detailTxt {
  composes: detailTxt from './subscription.module.scss';
}

.detailInnerTxt {
  composes: detailInnerTxt from './subscription.module.scss';
}

.subBlock2 {
  composes: subBlock2 from './subscription.module.scss';
}

.planListContainer {
  composes: planListContainer from './subscription.module.scss';
}

.planInnrList {
  composes: planInnrList from './subscription.module.scss';
}

.updateBtns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.btn1 {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #757575;
  font-weight: 600;
}

.btn2 {
  background: transparent linear-gradient(107deg, #7c087f 0%, #a506aa 100%);
  padding: 11px 33px;
  font-size: 0.75rem;
  color: #ffffff;
  line-height: 1.4;
  font-weight: 600;
  outline: none;
}

// =========================== influencer Profile screen
.profileImg {
  position: relative;
}

.uploadImg {
  position: absolute;
  left: 50px;
  top: 53px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.profile {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border: 1px solid v.$primary-dark;
  object-fit: cover;
}

.statusContainer {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  margin: 14px 0px;
  border-radius: 8px;
  background: #c7c7c72e;
  padding: 6px 8px;
}

.statusTxt {
  color: #3b3b3b;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 500;
}

.approvedTxt {
  color: #636363;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: 0.27px;
  display: flex;
  align-items: center;
}

.userStatus {
  border-radius: 8px;
  background: #f800ff2e;
  padding: 3px 13px;
  margin-left: 12px;
  color: #7c087f;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
  text-transform: capitalize;
}

.socialPlatformWrapper {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.socialPlatform {
  display: flex;
  gap: 7px;
  align-items: center;
}

.socialImage {
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  position: relative;
}

.socialTxt {
  color: #3b3b3b;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
  cursor: pointer;
}
