// =============================== deliverables css ===========================
.deliverableWrapper {
  display: flex;
  padding: 40px 50px;
  gap: 16px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    padding: 16px;
  }
}

.delLeft {
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff;
  border-radius: 1px;
  width: 225px;
  height: 100%;
  flex-shrink: 0;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.delRight {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.summaryTabList {
  composes: summaryTabList from './influencer-profile.module.scss';
}

.summaryTabListItem {
  composes: summaryTabListItem from './influencer-profile.module.scss';
  gap: 30px;
  width: 100%;
}

.activeTabItem {
  composes: activeTabItem from './influencer-profile.module.scss';
}

.subList {
  composes: subList from './influencer-profile.module.scss';
}

.activeTxt {
  white-space: nowrap;
  composes: activeTxt from './influencer-profile.module.scss';
}

.rightArrow {
  composes: rightArrow from './influencer-profile.module.scss';
}

.socialImg {
  composes: socialImg from './influencer-profile.module.scss';
}

.reelsBlock {
  box-shadow: inset 6px 8px 17px #fac2fc40, 0px 16px 60px #c3c3c333;
  background: #ff00a20a;
  border: 1px solid transparent;

  &.activeContnt {
    border: 1px solid #fcb6ff;
  }
}

.blockInnr {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  align-items: center;
  background: #ff00a20a;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.block1 {
  display: flex;
  align-items: center;
  gap: 22px;
}

.reelTxt {
  color: #14171f;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}

.block2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 350px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.allcTxt {
  color: #14171f;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
  line-height: 21px;
  text-transform: capitalize;
  max-width: 70px;
  width: 100%;
}

.addBtn {
  display: flex;
  align-items: center;
  background: transparent linear-gradient(104deg, #7c087f 0%, #a506aa 100%);
  padding: 11px 54px;
  width: 100%;
  max-width: 150px;
  justify-content: center;
  gap: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cmpltBtnWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.complteBtn {
  border: 1px solid #80f79899;
  border-radius: 15px;
  background: #d1ffd880;
  padding: 6px 11px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #0c9b16;
  font-weight: 600;
  justify-content: center;
}

.postsTableWrapper {
  padding: 16px;
  overflow-x: auto;
}

.postsTable {
  width: 100%;

  & > thead > tr > th {
    padding: 16px;
    color: #718096;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    font-weight: 400;

    &.actions {
      width: 170px;
    }
  }

  & > tbody > tr {
    background: #ff00a20a;
    // box-shadow: inset 6px 8px 17px #fac2fc40, 0px 16px 60px #c3c3c333;

    & > td {
      font-size: 16px;
      line-height: 21px;
      color: #14171f;
      font-weight: 500;
      padding: 16px;

      min-width: 230px;

      &:not(:first-child) {
        min-width: 140px;
      }

      & .postThumnail {
        width: 100px;
        height: 67px;
        object-fit: cover;
      }
    }
  }
}

.postStatus {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 8px;
}

.actionsIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}
