.chip {
  position: relative;
  margin: 0px;
  appearance: none;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  white-space: nowrap;
  text-decoration: none;
  outline: 0px;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  user-select: none;
  // cursor: pointer;

  &.small {
    font-size: 0.75rem;
    height: 28px;
    border-radius: 14px;
    .chipText {
      padding: 0 12px;
    }
  }

  &.medium {
    font-size: 0.825rem;
    height: 34px;
    border-radius: 17px;
    .chipText {
      padding: 0 14px;
    }
  }

  &.large {
    font-size: 1rem;
    height: 40px;
    border-radius: 20px;
    .chipText {
      padding: 0 20px;
    }
  }

  &.outlined {
    border: 1px solid transparent;
  }

  &.gray {
    color: rgba(51, 51, 51, 0.87);
    background-color: rgba(0, 0, 0, 0.08);
    border-color: #bdbdbd;
  }

  &.green {
    color: rgba(12, 155, 22, 0.87);
    background-color: #d1ffd880;
    border-color: #80f79899;
  }
  &.yellow {
    color: rgba(237, 172, 60, 0.87);
    background-color: #fcffd180;
    border-color: #f8b71999;
  }
  &.blue {
    color: rgba(29, 102, 242, 0.87);
    background-color: #1d66f214;
    border-color: #1d66f24d;
  }
  &.violet {
    color: rgba(78, 37, 121, 0.87);
    background-color: #4020611a;
    border-color: #40206159;
  }
  &.red {
    color: rgb(246, 66, 66, 0.87);
    background-color: #f9bcbc80;
    border-color: #ee454599;
  }
}

.chipText {
  text-transform: capitalize;
  font-weight: 500;
}
