@use 'assets/scss/variables' as v;

.campaignBtns {
  padding: 25px;
  display: flex;
  gap: 15px;
}

.panAndProfile {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.uploadBtn {
  color: #7e0881;
  border: 1px solid #7c087f;
  padding: 17px 25px;
  font-size: 0.75rem;
  font-weight: 600;
}

.displayNone {
  display: none;
}

.createCampaignBtn {
  border: 1px solid #e4e4e5;
  padding: 15px;
  font-size: 0.875rem;
  color: #e4e4e5;
  font-weight: 600;
  width: 100%;
}

.updateBtn {
  width: 100%;
  background: transparent linear-gradient(101deg, #7c087f 0%, #a506aa 100%);
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 15px 0px;
}

.campListWrap {
  padding: 0 25px;
  max-height: calc(100vh - 245px);
  overflow-y: auto;
}

.campList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listItem {
  color: #ff00a2;
  font-size: 0.938rem;
  font-weight: 500;
  line-height: 1.4;
}

.createListBtns {
  composes: updateBtn;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  max-width: 166px;
  width: 100%;
  justify-content: center;
}

.inputWrapper {
  composes: inputWrapper from './search.module.scss';
  max-width: 280px;
  width: 100%;
  padding: 13px;
}

.custmIn {
  composes: custmIn from './search.module.scss';
  width: 100%;
}

.createCustmListWrap {
  display: flex;
  align-items: center;
  padding: 25px;
  gap: 14px;
}

.createAddBtns {
  padding: 16px 0px;
  max-width: 166px;
}

// =============================== questionare dialog
.questionareContainer {
  padding: 0px 25px;
}

.quesTitle {
  color: #7c087f;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 21px;
}

.inputRadio {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
}

.innrTnput {
  display: flex;
  gap: 10px;
  align-items: center;
}

.inputContainer {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  user-select: none;
  color: #808080;
}

.innrSubIput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioField {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ff00a2;
}

// .inputContainer .innrSubIput:checked ~ .radioField {
//     background-color: #ff00a2;
// }

.radioField:after {
  content: '';
  position: absolute;
  display: none;
}

.innrSubIput:checked ~ .radioField:after {
  display: block;
}

.radioField:after {
  top: 2.5px;
  left: 2.5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff00a2;
}

.canBtn {
  border: 1px solid #ababac;
  color: #ababac;
}

.questnrBtn {
  width: 75%;
  margin: auto;
}

.checkBox {
  composes: checkBox from '../../../components/common/Table/Table.module.scss';
}

.dashboardTop {
  composes: dashboardTop from './dashboard.module.scss';
}

.transBg {
  composes: transBg from './dashboard.module.scss';
}

.innrWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.creationTxt {
  color: #19191c;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
}

.categoryButtons {
  composes: categoryButtons from './search.module.scss';
  padding-left: 0px;
  flex-wrap: nowrap;
}

.influencerCateBtn {
  composes: influencerCateBtn from './search.module.scss';
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 0;
  padding: 12px 52px;
  width: 100%;
  justify-content: center;
}

.whiteActiveBtn {
  composes: whiteActiveBtn from './search.module.scss';
}

.counTxt {
  border: 1px solid #ff00a2;
  color: #ff00a2;
  font-size: 0.625rem;
  font-weight: 600;
  padding: 1px 4px;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  text-align: center;
}

.activeCountTxt {
  background: #ff00a2;
  color: #fff;
}

// ================================create campaign dialog
.secBody {
  padding: 25px 25px;
  overflow: auto;
  height: calc(100vh - 297px);
}

.createInfFooter {
  composes: secBody;
  height: auto;
}

.secBodyWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  & > img {
    width: 26px;
    max-height: 26px;
    object-fit: contain;
    flex-shrink: 0;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.popupInputWrap {
  width: 100%;
  flex-shrink: 1;
  overflow: hidden;
}

.hTxt {
  color: #888888;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
}

.inputTxt {
  border-bottom: 2px solid #ff00a2;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 12px;
  letter-spacing: 0.13px;
  color: #3b3b3b;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
  outline: none;
}

.timeWrapp {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  width: 100%;

  .headWrapper {
    display: inline-flex;
    align-items: center;
    gap: 6px;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.timeDiv {
  display: flex;
  gap: 15px;
  margin-top: 14px;
}

.innrTimeTxt {
  border-radius: 5px;
  background: #f1f4f8;
  letter-spacing: 0.13px;
  color: #3b3b3b;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  padding: 8px 10px;
  cursor: pointer;
}

.ageInput {
  width: 30px;
  padding-right: 5px;
  outline: none;
  background: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.locationWrap {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
}

.locaTxt {
  letter-spacing: 0.11px;
  color: #3b3b3b;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 500;
}

.downImg {
  width: 18px;
}

.tagsField {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tags {
  border-radius: 14px;
  background: #f6e8f3;
  color: #3b3b3b;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 4px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    flex-shrink: 0;
  }
}

.activeTags {
  background: #ff00a2;
  color: #fcfcfc;
}

.addNewBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
  border: 1px solid #ff00a2;
  border-radius: 50px;
  padding: 3px 12px 2px;
  color: #ff00a2;
  font-size: 0.875rem;
  font-weight: 500;
}

.uploadFolderSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px;
  border: 2px dashed #d0d0d059;
  background: #f8f9fa;
  margin-top: 14px;
  cursor: pointer;
}

.previewContainer {
  display: flex;
  gap: 16px;
  margin-top: 10px;
  align-items: center;

  & .fileInfoWrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  & .preview {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
  }

  & .filename {
    letter-spacing: 0.11px;
    color: #3b3b3b;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .filesize {
    letter-spacing: 0.11px;
    color: #3b3b3b;
    font-size: 0.75rem;
  }

  & .viewLink {
    flex-shrink: 0;
  }
}

.dragDropTxt span {
  font-weight: 600;
}

.dragDropTxt {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  color: #19191c;
}

.folderSizeTxt {
  color: #888888;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
}

.nxtWrap {
  justify-content: flex-end;
  padding: 0px;
  gap: 30px;
}

.genderBox {
  display: flex;
  gap: 28px;
  margin-top: 10px;
}

.genderLabel {
  font-size: 14px;
  letter-spacing: 0.13px;
  color: #3b3b3b;
  font-weight: 500;
}

.ageGroup {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 7px;
}

.prevBtn {
  color: #757575;
  font-size: 0.875rem;
  font-weight: 500;
}

// ================================ influencr third style
.influencerDeliverableContainer {
  box-shadow: inset 6px 8px 17px #fac2fc40, 0px 16px 60px #c3c3c333;
  border: 1px solid #fcb6ff;
  background: #ff00a208;
  /* padding: 15px 20px; */
  margin-top: 24px;
}

.deliverBlock1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #ebe7eb;
}

.delivSubBlock {
  display: flex;
  gap: 10px;
  align-items: center;
}

.delivSubBlock img {
  cursor: pointer;
}

.delSubInnrBlck {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nameTxt {
  color: #14171f;
  font-size: 0.938rem;
  font-weight: 600;
  line-height: 1.4;
}

.delSubSecndBlck {
  display: flex;
  gap: 10px;
}

.deliverBlock2 {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reelTxt {
  color: #14171f;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
  display: inline;
}

.reelTxt span {
  font-weight: 600;
}

.sectinBottomButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.expctedReachCal {
  width: 30%;
  cursor: pointer;
}

.crBtn {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  gap: 26px;
}

.expcTxt {
  color: #7f7f7f;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 500;
}

.amntTxt {
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.wrapCont1 {
  display: flex;
  gap: 5px;
  background: rgba(205, 51, 149, 0.1);
  padding: 10px 20px 0;
}

.wrapLink {
  background: rgba(255, 255, 255, 0.2509803922);
  cursor: pointer;
  letter-spacing: 0.1px;
  color: #3b3b3b;
  font-size: 0.688rem;
  font-weight: 500;
  padding: 4px 20px;
  text-transform: capitalize;
}

.activeWrapLink {
  background: #ffffff;
  color: #ff00a2;
}

.delImg {
  cursor: pointer;
}

.reelCounts {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  border: 1px solid #ff00a2;
  border-radius: 4px;
  padding: 8px 4px;

  & > * {
    flex-shrink: 0;
  }

  & > button {
    padding: 5px;

    & > img {
      height: 11px;
      width: 11px;
    }
  }
}

.reelCnt {
  letter-spacing: 0.13px;
  color: #3b3b3b;
  font-size: 0.875rem;
  font-weight: 500;
  width: 30px;
  padding: 0 5px;
  outline: none;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.reelTitle {
  color: #858585;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
}

// ============================ brand edit profile screen ==================
.editFormWrapper {
  max-height: calc(100vh - 273px);
  overflow: auto;
  padding: 0 25px;
}

.editProfileContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 16px 25px 25px;
}

.formGroup {
  margin-bottom: 30px;
}

.addressFormGroup {
  margin-bottom: 16px;
}

.formLabel {
  display: block;
  color: #757575;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.formInputWrapper {
  position: relative;
}

.formInput {
  outline: none;
  border-bottom: 2px solid #ff00a2;
  width: 100%;
  padding: 18px 16px;
  color: #4e4d4d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

.formPanInput {
  outline: none;
  border-bottom: 2px solid #ff00a2;
  width: 100%;
  padding: 9px 1px;
  color: #4e4d4d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

.otpVerification {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 11px;
  input {
    width: 100%;
    text-align: center;
    outline: none;
    border: none;
    border-bottom: 2px solid #ff00a2;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: v.$primary-dark;
    padding: 16px 0 10px;

    // Following removes number input arrow keys
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.verifyBtn {
  border: 1px solid #7c087f;
  background: #fc9fff3b;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ff00a2;
  padding: 4px 16px;
  transform: translateY(-5px);
}

.otpTimer {
  font-size: 0.875em;
  line-height: 1.2;
  font-weight: 600;
  color: #cd3395;
}

.descriptionI {
  position: absolute;
  left: 21%;
  top: 4%;
}

.formInputEndAdorment {
  position: absolute;
  right: 20px;
  top: 20px;
}

.formInputStartAdorment {
  position: absolute;
  left: 16px;
  top: 17px;
  color: #4e4d4d;
}

.formInputStartAdorment + .formInput {
  padding-left: 75px;
}

.formError {
  composes: error from '../common.module.scss';
}

.selectWrapper {
  line-height: 1;
}

.selectButton {
  text-align: left;
  border-bottom: 2px solid v.$pink;
  padding: 18px 10px 14px 16px;
  outline: none;
  letter-spacing: 0.15px;
  color: v.$black-light;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
  background: v.$white;
  width: 100%;
  min-height: 35px;

  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;

  .selectedValues {
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: auto;
    color: #4e4d4d;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    white-space: nowrap;

    & .singleSelected {
      font-size: 14px;
      font-weight: 500;
      color: #19191c;
    }

    & .selectPlaceholder {
      color: #828282;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .selectedValue {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    gap: 4px;
    padding: 5px 10px;
    border-radius: 30px;
    background: v.$pink;

    color: v.$white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    .cross {
      padding: 4px;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .dropdownIcon {
    flex-shrink: 0;
  }
}

.selectOptions {
  max-height: 240px;
  width: 100%;
  background: v.$white;
  padding: 4px 0;
  z-index: 1;
  outline: none;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  overflow: auto;

  & > .selectOption {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: v.$txt-color;
    padding: 6px 12px;
    cursor: pointer;
    // white-space: nowrap;

    .optionLabel {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .checkMark {
      flex: 0 0 auto;
      appearance: none;
      width: 1rem;
      height: 1rem;
      border: 2px solid v.$pink;
      border-radius: 0.25rem;
      position: relative;
      display: grid;
      place-content: center;
      cursor: pointer;
      background-color: v.$white;
    }

    &.active {
      background: rgba(v.$primary, 0.12);
    }

    &.disabled {
      opacity: 0.7;
    }

    &.selectOptionActive {
      .checkMark {
        background: v.$pink;

        &:after {
          content: '';
          background-color: transparent;
          width: 5px;
          height: 10px;
          border: solid v.$white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) translateY(-1px) translateX(-1px);
        }
      }
    }
  }
}

.stateDiv {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }

  @media (min-width: v.$sm) {
    gap: 32px;
    flex-wrap: nowrap;

    & > div {
      max-width: 50%;
    }
  }
}

.gstDiv {
  display: flex;
  border-bottom: 2px solid #ff00a2;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  gap: 20px;
  margin-bottom: 6px;
}

.gstSubPart {
  display: flex;
  align-items: center;
  gap: 10px;
  outline: none;
  overflow: hidden;
}

.gstUploadWrapper {
  flex-shrink: 0;
}

.gstFileUploadField {
  composes: formInput;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.docRemoveBtn {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}

.uploadFileBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px dashed #7c087f;
  padding: 7px 15px;
  color: #7c087f;
  cursor: pointer;
  border-radius: 4px;

  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
}

// ========================== influencer edit profile screen ==========================
.usrProfile {
  position: relative;
  width: 111px;
  margin-bottom: 30px;
  cursor: pointer;

  img {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid v.$primary-dark;
  }

  .uploadIconImage {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.usrUploadImg {
  position: absolute;
  top: 64%;
  left: 73px;
}

.accountLink {
  margin-top: 13px;
  composes: accountLink from './onboarding.module.scss';
}

.socialImage {
  composes: socialImage from './onboarding.module.scss';
}

.verify {
  composes: verify from './onboarding.module.scss';
}

.activeVerifyBtn {
  composes: activeVerifyBtn from './onboarding.module.scss';
}

.socialInputButton {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ff00a2;
  padding: 13px 18px;
}

.inputHolder {
  width: 100%;
  outline: none;
  padding-right: 18px;
}

.socialVerifyBtns {
  width: 20%;
}

.influencerType {
  flex-direction: row;
  margin-bottom: 30px;
}

.fieldInf {
  top: 3px;
  height: 14px;
  width: 14px;
}

.innrSubIput:checked ~ .fieldInf:after {
  display: block;
}

.fieldInf:after {
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
}

// ================ campaign creation popup=============
.socialMediaBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.postCountContnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 15px 20px;
  gap: 14px;

  & > * {
    flex-shrink: 0;
  }
}

.leftSec {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.arrImg {
  margin-top: 25px;
  cursor: pointer;

  & .leftArrow {
    transform: rotate(180deg);
  }
}
