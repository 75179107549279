.footer {
  display: flex;
  gap: 10px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  .item {
    font-size: 14px;
    color: #292930;
    line-height: 1.2;
    font-weight: 400;
    padding-right: 10px;
    border-right: 1px solid #292930;
    text-decoration: none;

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}
