// header ==============================
.header {
  composes: header from '../../../components/common/Header/Header.module.scss';
  background: #3b1258;
  box-shadow: none;
}

.navContainer {
  composes: navContainer from '../../../components/common/Header/Header.module.scss';
  background: #3b1258;
}

.show {
  composes: show from '../../../components/common/Header/Header.module.scss';
}

.closeBtnWrapper {
  composes: closeBtnWrapper from '../../../components/common/Header/Header.module.scss';
}

.navLinks {
  composes: navLinks from '../../../components/common/Header/Header.module.scss';
}

.navItems {
  composes: navLinks from '../../../components/common/Header/Header.module.scss';
  color: #b7a7c1;
}

.signInBtn {
  color: #e994ef !important;
}

.signupBtn {
  border: 1px solid #fca2ff;
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #e994ef;
}

.navBtn {
  composes: navBtn from '../../../components/common/Header/Header.module.scss';
}

.navCheck {
  composes: navCheck from '../../../components/common/Header/Header.module.scss';
}

// .navCheck {
//     span {
//         composes: span from "../../../components/common/Header/Header.module.scss";
//     }
// }

// ====================================

.bannerHeading {
  text-align: center;
  width: 100%;
  max-width: 992px;
  margin: auto;
  left: 0;

  @media (max-width: 992px) {
    // padding: 10px;
  }
}

.bannerWrapper {
  height: 1100px;
  position: relative;

  @media (max-width: 992px) {
    height: 730px;
  }

  & > .backgroundSvg,
  & > .backgroundDotsImage {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  & > .backgroundSvg {
    z-index: -3;
  }

  & > .backgroundDotsImage {
    z-index: -2;
    mix-blend-mode: color-burn;
  }
}

.bannrInnrWrapper {
  padding-top: 150px;
  margin-top: -110px;
}

.headingTxt {
  font-size: 46px;
  line-height: 69px;
  color: #ffffff;
  font-weight: 600;
  text-wrap: balance;

  @media (max-width: 992px) {
    font-size: 30px;
    line-height: 1.8;
  }
}

.paragraphTxt {
  color: #ffffff;
  opacity: 0.9;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 20px;
  text-wrap: balance;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 26px;
  }
}

.bannerBtns {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.bannerBtns a {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  max-width: 210px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.startCampBtn {
  background: transparent linear-gradient(103deg, #8a098d 0%, #c605cc 100%);
  padding: 14px 21px;
  color: #ffffff;
}

.bookCampBtn {
  border: 1px solid #ffffff;
  padding: 14px 32px;
  color: #ffffff;
}

.multiImages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 0 30px 70px;
  width: 100%;
  max-width: 1300px;
  margin: auto;

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 1280px) {
    transform: scale(0.8);
  }
}

.blockImg1 {
  width: 40%;
  max-width: 380px;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.rankImg {
  margin: auto;
  border-radius: 12px;
  width: 376px;
}

.rank2Img {
  margin: auto;
  border-radius: 12px;
}

.titleTxt {
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  margin: 7px auto 0px;
  width: 100%;
  max-width: 300px;
  margin-top: 14px;
  text-wrap: balance;

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.blockImg2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(2.5vw);

  @media (max-width: 1100px) {
    width: 100%;
    transform: translateX(0);
  }
}

.graph1Img {
  width: 100%;
  max-width: 180px;
  margin: auto;
}

.blockImg3 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 117px;
  width: 30%;
  max-width: 350px;
  transform: translateX(200px) translateY(-50px);

  @media (max-width: 1439px) {
    transform: translateX(117px) translateY(0px);
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.img1 {
  position: absolute;
  left: -240px;
}

.img2 {
  position: absolute;
  top: 240px;
  left: -220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph2Img {
  width: 100%;
  max-width: 225px;
  margin: auto;
}

.title2Txt {
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  width: 100%;
  max-width: 208px;
  margin-top: 12px;
}

.MessageSubjectIcons {
  display: inline;
}

h2.message-subject {
  font-size: 16px;
  color: #fff;
  margin: 0px;
}

.messageBodyCheckmark {
  position: relative;
  float: left;
  padding: 10px;
}

.messageBodyCheckmark:before {
  content: '';
  background: url(../../images//youtube-img.svg) no-repeat;
  display: inline-block;
  margin-right: 4px;
  position: absolute;
  top: 6px;
  left: -3px;
  height: 14px;
  width: 21px;
}

.videoTxt {
  font-size: 16px;
  line-height: 1.7;
  width: 100%;
  margin-right: 38px;
  color: #ffffff;
  margin-top: 10px;
  max-width: 250px;
}

// ====================================  subscribe plan section ==================
.elevationBrands {
  margin-top: 70px;
  margin-bottom: 70px;

  @media (max-width: 992px) {
    padding: 0 20px;
    margin-bottom: 40px;
    margin-top: 15px;
  }
}

.elevationTxt {
  font-size: 50px;
  line-height: 70px;
  color: #24263a;
  text-align: center;
  font-weight: 800;
  width: 100%;
  margin: auto;
  max-width: 1100px;

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 38px;
    max-width: 100%;
  }
}

.exTxt {
  font-size: 20px;
  line-height: 35px;
  color: #808080;
  font-weight: 500;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 30px;
  }
}

.subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 100px;
  cursor: pointer;
  background: transparent
    linear-gradient(180deg, #ffffff 69%, #f2d8f2 275%, #f2d8f261 54%);

  @media (max-width: 992px) {
    gap: 30px;
  }
}

.subscribePlans {
  width: 100%;

  @media (max-width: 992px) {
    overflow: auto;
    width: 100%;
    height: auto;
  }
}

.subInnrPlanWrapper {
  display: flex;
  gap: 37px;
  justify-content: center;

  @media (max-width: 992px) {
    justify-content: initial;
    padding: 20px 10px;
  }
}

.subscriptionContainer {
  box-shadow: 0px 4px 30px #ffdbfd;
  border: 1px solid #ff00a21a;
  border-radius: 30px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 410px;

  @media (max-width: 992px) {
    min-width: 340px;
  }
}

.innerSubscriptionColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.innerSubscriptionColumn h2 {
  font-size: 36px;
  line-height: 35px;
  color: #24263a;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 35px;
}

.pricingExpiry h3 {
  font-size: 50px;
  font-weight: 600;
  color: #cc1ccf;
  margin-bottom: 15px;
}

.pricingExpiry h3 span {
  font-size: 20px;
}

.pHeight {
  display: flex;
  flex-direction: column;
  gap: 14px;

  padding-left: 35px;
  height: 100%;
}

.trialTime p {
  color: #24263a;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  background: #f7d6f8;
  border-radius: 40px;
  padding: 11px 37px;

  @media (max-width: 992px) {
    text-align: center;
    font-size: 16px;
  }
}

.trialTime {
  margin-bottom: 50px;
}

.pHeight li {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #808080;
  position: relative;
}

.pHeight li:before {
  background: url(../../images/accept-pink.png) no-repeat;
  content: '';
  height: 24px;
  left: -34px;
  position: absolute;
  top: 3px;
  width: 24px;
}

.buyNowBtn {
  margin-top: 50px;
  width: 100%;
}

.editBooking {
  // border: 1px solid #900794;
  border: 1px solid #cc1ccf;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 16px 45px;
  color: #cc1ccf;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  max-width: 320px;
  margin: auto;

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 16px 7px;
  }
}

.subscribeContainerActive .hoverBooking {
  background: transparent linear-gradient(270deg, #ff5cc3 0%, #cb1ad0 100%);
  color: #fff;
}

.subscribeContainerActive {
  box-shadow: 0px 4px 30px #ffdbfd;
  border: 3px solid #ff00a2;
  border-radius: 30px;
  transform: scale(1.02);
  transition: 0.5s ease;
  background: #ff00a20d;
}

.subscribeBtns {
  box-shadow: 4px 4px 30px #ffdbfd;
  border: 1px solid #ff00a21a;
  border-radius: 100px;
  padding: 10px 15px;
  display: flex;
  align-items: center;

  & > button {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 20px;

    &.activeBtn {
      color: #fff !important;
      background: transparent linear-gradient(270deg, #ff5cc3 0%, #cb1ad0 100%);
    }
  }

  @media (min-width: 640px) {
    padding: 6px 10px;

    & > button {
      font-size: 20px;
      padding: 16px 40px;
      border-radius: 40px;
    }
  }
}

// ================================ Top Influencer Categories======================
.influencerCategoryContainer {
  background-size: 100% 100%;
  // height: 1189px;
  overflow: auto;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }

  &:before {
    opacity: 0.3;
    background: transparent
      linear-gradient(155deg, #ffffff 0%, #f2d8f2 92%, #f2d8f261 100%) 0% 0%
      no-repeat padding-box;
  }

  &:after {
    background: url('../../../assets/images/influencer-text.svg');
    background-repeat: no-repeat;
    background-position: bottom 100px left 10px;

    @media (min-width: 1024px) {
      background-position: bottom 100px left 50%;
    }
  }
}

.navListWrapper {
  margin-top: 16px;
  overflow-x: auto;
  padding: 0 16px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  border-bottom: 2px solid #deddde;
  width: max-content;
  margin: auto;
  min-width: 100%;
}

.navListItem {
  font-size: 18px;
  color: #0e0e0e;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  white-space: nowrap;
}

.activeNavHeading {
  color: #b030b4;
  font-weight: 600;
  border-bottom: 3px solid #cc47cf;
  box-shadow: 0px 6px 6px -6px #8e1591;
  padding-bottom: 6px;
}

.influencerCard {
  width: 100%;
  height: 100%;
  position: relative;

  .cardImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(214, 51, 219, 0.4) 0%,
      rgba(28, 33, 53, 0.2) 40%,
      rgba(59, 18, 88, 0.8) 100%
    );
  }

  .cardContent {
    position: relative;
    z-index: 1;
    padding: 16px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;

    & > h2,
    & > p {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.4;

      letter-spacing: 0px;
    }

    & > h2 {
      font-weight: bold;
      color: #ffffffcc;
    }

    & > p {
      font-weight: medium;
      color: #ffffff66;
    }

    .socialIcons {
      margin-top: 8px;
      display: flex;
      gap: 16px;
    }
  }
}

// ================================== our services ==============================
.ourServicesContainer {
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 992px) {
    padding: 0 10px;
  }
}

.serviceTxt {
  font-size: 60px;
  line-height: 1.4;
  color: #24263a;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  text-wrap: balance;

  @media (max-width: 992px) {
    font-size: 34px;
  }
}

.serviceTxt2 {
  font-size: 18px;
  line-height: 30px;
  color: #808080;
  width: 100%;
  max-width: 735px;
  text-align: center;
  margin: auto;
  text-wrap: balance;
}

.servicesInnrContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  justify-content: center;
  margin-top: 100px;

  @media (max-width: 992px) {
    padding: 0 15px;
    margin-top: 45px;
  }
}

.contInnrPart {
  box-shadow: 4px 4px 30px #ffdbfd;
  border: 1px solid #ff00a21a;
  border-radius: 12px;
  padding: 32px 45px;
  width: 100%;
  max-width: 387px;
  display: flex;
  gap: 40px;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: transparent linear-gradient(90deg, #7e0982 0%, #e137e5 100%) 0%
      0% no-repeat padding-box;

    & > img {
      filter: brightness(0) invert(1);
    }

    & > .serviceTxt3 {
      color: #ffffff;
    }
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  .serviceTxt3 {
    font-size: 26px;
    line-height: 1.4;
    color: #0e0e0ecc;
    font-weight: 600;
    text-wrap: balance;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
}

// ================================= idea banner section =====================

.ideaBanner {
  margin: 100px 0px 50px;
  width: 100%;
  overflow: hidden;
  padding: 20px 0px;

  @media (max-width: 992px) {
    margin: 50px 0px;
  }

  .ideaBannerInnr {
    height: 182px;
    margin: 72px 0;
    background-color: #b222b73b;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

  .moverWrraper {
    position: relative;
    width: 120vw;
    flex-shrink: 0;
    transform: rotate(-7deg);
    height: inherit;
    background: #f6e7f7;
    // background: rgb(124, 8, 127);
    // background: linear-gradient(
    //   180deg,
    //   rgba(124, 8, 127, 1) 0%,
    //   rgba(161, 22, 165, 1) 50%,
    //   rgba(232, 58, 236, 1) 100%
    // );
    overflow-x: hidden;

    @media (min-width: 1080px) {
      transform: rotate(-5deg);
    }

    .mover {
      position: absolute;
      height: inherit;
    }

    .logosWrapper {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      height: inherit;

      @media (min-width: 1080px) {
        gap: 60px;
      }
    }

    & img {
      height: 54px;
      width: auto;
      // filter: brightness(0) invert(1);
    }
  }
}

img.ideaBannerImg {
  width: 100%;
  transform: scale(1.04) translate(10px, 2px);
}

// ========================High Impact Work For Brands & Creators ====================
.sliderContainer {
  margin: 98px 0px;
  position: relative;

  @media (max-width: 992px) {
    margin: 40px 0px;
  }
}

.impactTxt {
  width: 100%;
  margin: auto;
  padding-bottom: 70px;

  @media (max-width: 992px) {
    padding-bottom: 0px;
  }
}

.slideHeader {
  position: absolute;
  left: 0;
  right: 0;
}

.creatorBg {
  width: 100%;
  filter: blur(10px);
  height: 100%;
}

.blurHeading {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
}

.blurInnr {
  background: transparent
    radial-gradient(
      closest-side at 42% 52%,
      #ffffff 0%,
      #f8e9f8 100%,
      #f2d8f2 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
  filter: blur(34px);
  position: absolute;
  height: 145px;
  width: 100%;
  z-index: -1;
}

.sliderTopBody {
  padding-top: 130px;
}

// ================================= feature banner section =====================

.featureBanner {
  overflow: visible;
  margin-bottom: 70px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 792px) {
    margin-bottom: 25px;
  }
}

img.featureBnnrImg {
  height: calc(100vh - 200px);
  max-height: 744px;
  filter: grayscale(1);
}

.scrollContainer {
  overflow: hidden;
  will-change: transform;
}

.featuredImagesWrapper {
  display: flex;
  padding: 0 100px;
  gap: 8px;

  &:hover .featureBnnrImg {
    filter: none;
  }
}

// ============================= What Are They Saying? ==============================
.slidElement {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > .contentWrapper {
    flex-grow: 1;
  }
}

.sliderHeader {
  margin: 80px 0px;

  @media (max-width: 992px) {
    margin: 20px 0px;
  }
}

.sliderBody {
  padding: 0 100px;

  @media (max-width: 992px) {
    padding: 0 12px;
  }
}

.feedbackWrapper {
  margin-bottom: 70px;

  @media (max-width: 992px) {
    margin-bottom: 50px;
    padding: 0 5px;
  }
}
.feebackQuoteTxt {
  color: #0e0e0e;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}

.userBox {
  display: flex;
  gap: 12px;
  align-items: center;
}

.userName {
  font-size: 19px;
  color: #0e0e0e;
  font-weight: 500;
}

.designationName {
  font-size: 17px;
  color: #0e0e0e;
  font-weight: 500;
}

.infTestimonialContainer {
  background: #ffffff;
  position: relative;
  padding: 20px;
  overflow: hidden;
  height: 100%;

  &::before,
  &::after,
  & > .leftBorder {
    width: 24%;
    height: 10px;
    content: '';
    position: absolute;
    background: #e33de8;
    left: 0;
  }

  & > .leftBorder {
    height: 100%;
    width: 10px;
    top: 0;
  }

  &::before {
    top: 0;
  }

  &::after {
    width: 10%;
    bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 0;

    &::before,
    &::after,
    & > .leftBorder {
      display: none;
    }
  }
}

.infTestimonial {
  display: flex;
  background: #f6cbf6a6;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.infTestimonialImg {
  height: 100%;
  width: 275px;
  max-width: none;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: 320px;
  }
}

.infTestimonialData {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.infTestimonialTextSection {
  flex-grow: 1;
}

.infTestimonialText,
.infTestimonialName,
.infTestimonialDesignation {
  color: #0e0e0e;
  font-weight: 500;
}

.infTestimonialText {
  font-size: 18px;
  margin-top: 18px;
}

.infTestimonialName {
  font-size: 19px;
}
.infTestimonialDesignation {
  font-size: 17px;
}

.infTestimonialSocialsSection {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;

  & > a {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 19px;
    font-weight: 600;
    color: #e33de8;

    &:not(:last-child) {
      // margin-right: 40px;
      margin-right: 12px;
    }

    & > img {
      width: 24px;
      height: 24px;
    }
  }
}

// ==============================Post Your Campaign====================================
.postCampaignWrapper {
  background: url(../../images/post-campaign-bg.png) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 70px;
  height: auto;
}

.postCampaignInnr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 100px;
  gap: 16px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    transform: translate(0px, 0px);
    gap: 40px;
    padding: 34px 20px;
    justify-content: center;
  }
}

.campaignTxt1 {
  color: #ffffff;
  font-size: 44px;
  line-height: 1.5;
  font-weight: 800;

  @media (max-width: 992px) {
    font-size: 34px;
    text-align: center;
  }

  @media (max-width: 567px) {
    font-size: 28px;
  }
}

.campaignTxt2 {
  color: #ffffff;
  font-size: 20px;
  initial-letter: 30px;
  font-weight: 500;

  @media (max-width: 992px) {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
}

.postCampBtn {
  flex-shrink: 0;
  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
}

.postBtn {
  border-radius: 12px;
  background: #ffffff;
  padding: 22px 24px;
  color: #820a85;
  font-size: 19px;
  font-weight: 600;
}

// =======================================question section =========================

.questionSec {
  padding: 0 100px;
  margin: 100px auto 70px;
  max-width: 1240px;

  @media (max-width: 992px) {
    padding: 0px 20px;
    margin-top: 40px;
    margin-bottom: 28px;
  }
}

.queTxt {
  font-size: 42px;
  line-height: 64px;
  color: #0e0e0e;
  font-weight: 800;
  width: 100%;

  @media (max-width: 992px) {
    max-width: 100%;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}

.assistanceTXT {
  font-size: 18px;
  line-height: 29px;
  color: #0e0e0e;
  max-width: 1024px;
  margin: 20px auto;

  @media (max-width: 992px) {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
  }
}

.moreFaqBtn {
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  margin-top: 32px;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 992px) {
    justify-content: center;
    margin: 32px auto 0px;
  }
}

.queryBlock1 {
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
}

// ==================================== Landing Page =====================

.brandingSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 162px;
  margin-top: 130px;

  @media (max-width: 1200px) {
    gap: 100px;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 35px;
    margin-bottom: 20px;
  }
}

.innrBrand {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
}

.innrBrand img {
  width: 102px;
  height: 86px;
  object-fit: contain;
}

.subscribeUserTxt {
  font-size: 50px;
  font-weight: 800;
  color: #24263a;
  line-height: 76px;
  margin-top: 20px;
  margin-bottom: 5px;

  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 1.5;
    margin: 0px;
  }
}

.numbersImage {
  width: 100%;
  max-width: 475px;
  margin: -50px;

  @media (max-width: 992px) {
    margin: 0;
    max-width: 375px;
  }
}

.subsTitle {
  font-size: 28px;
  color: #6c6c6c;
  line-height: 42px;
  font-weight: 500;

  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 1.4;
    margin: 0px;
  }
}

// ====================== third Section(work) ==============
.workContainer {
  background: url(../../images/work-img.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 90px;
  height: auto;
  padding: 77px;

  @media (max-width: 992px) {
    margin: 0px;
    padding: 30px 10px;
  }
}

.workInnrWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    margin-top: 15px;
  }
}

.workListContainer {
  width: 100%;
  max-width: 521px;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    gap: 0;
  }
}

.workListInnr {
  padding: 18px 0px;
  border: 2px solid transparent;

  @media (max-width: 992px) {
    padding: 10px;
  }
}

.workSub {
  border-color: #d633db4d;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgb(155 103 162 / 14%);
  margin-top: 20px;
  margin-bottom: 38px;

  @media (max-width: 992px) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

.workListBtn {
  letter-spacing: 0.56px;
  color: #24263a;
  font-size: 28px;
  font-weight: 600;
  padding-left: 50px;
  margin-bottom: 18px;
  position: relative;
  width: 100%;
  text-align: left;

  @media (max-width: 992px) {
    font-size: 20px;
    padding-left: 25px;
    margin-bottom: 12px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 17px;
    width: 17px;
    left: 20px;
    top: 10px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    background: #fe99da;
    box-shadow: none;

    @media (max-width: 992px) {
      left: 3px;
      top: 4px;
    }
  }

  &[aria-expanded='true']:before {
    background: #ff00a2;
    box-shadow: 0 4px 8px #e1b8e2;
  }
}

.workListTxt {
  overflow: hidden;
  color: #808080;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  padding: 0 50px;

  @media (max-width: 992px) {
    padding: 0px;
    margin-top: 5px;
  }
}

.activeBorder {
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #b584ce26 61%, #641582cc 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 4px 5px 10px #e79ce380;
  // border: 1.5px solid #fff;
  border-radius: 12px;
}

// ================ case studies =======================

.caseStudiesWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  max-width: 1240px;
  margin: auto;
}

.caseStudyCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  border: 2px solid #f3dff3;
  padding: 16px 16px 28px;
  transition: all 0.3s ease;

  &:hover {
    border: 2px solid #e33de8;
    box-shadow: 4px 4px 30px #ffdbfd;
  }
}

.caseStudyVideo {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  background: #f6e7f7;
}

.caseStudyTitle {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.26px;
  color: #0e0e0ecc;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.caseStudyDescSection {
  flex-grow: 1;
}

.caseStudyDesc {
  font-size: 16px;
  opacity: 0.6;
  color: #0e0e0e;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.caseStudyReadMore {
  display: inline-flex;
  font-size: 18px;
  font-weight: 600;
  gap: 8px;
}
