// ===================== Center Zoom Slider ==========================
.custom-slider {
  .swiper-slide {
    width: 345px;
    max-width: 75%;
    padding-top: 220px;

    .slider-element {
      transition: all 0.3s ease;
      transform: scale(1.2) translateY(-40px);
      // border: 2px solid white;
      // border-radius: 28px;
      // overflow: visible;
      // width: 345px;
      // height: 434px;
      // background: gray;
      // padding: 34px;

      // TODO: merge these two gradients by absolute divs
      // background: transparent linear-gradient(0deg, #00000000 0%, #000000 100%)
      //   0% 0% no-repeat padding-box;
      // background: transparent
      //   linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
      //   padding-box;
    }
  }

  .swiper-slide-active {
    z-index: 10;
    .slider-element {
      transform: translateY(-140px) scale(1.2);

      // TODO: merge these two gradients by absolute divs
      // background: transparent linear-gradient(0deg, #00000000 0%, #c61ccb 100%)
      //   0% 0% no-repeat padding-box;
      // background: transparent
      //   linear-gradient(180deg, #00000000 0%, #c61ccb 100%) 0% 0% no-repeat
      //   padding-box;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    z-index: 9;
    .slider-element {
      transform: translateY(-100px) scale(1.2);
    }
  }
  .swiper-slide-next + .swiper-slide {
    z-index: 8;
  }
  .swiper-slide-next + .swiper-slide + .swiper-slide {
    z-index: 7;
  }
  .swiper-slide-next + .swiper-slide + .swiper-slide + .swiper-slide {
    z-index: 6;
  }
  .swiper-slide-next
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide {
    z-index: 5;
  }
}
// custom sider progressbar

.progressbar-wrapper {
  align-items: center;
  gap: 32px;
  margin-top: 24px;
  padding-left: 60px;
  padding-right: 60px;
  // padding-top: 70px;
  display: none;

  @media (min-width: 660px) {
    display: flex;
  }
}

.custom-progressbar {
  flex-grow: 1;
  height: 5px;
  background: #d633db24;
  position: relative;

  .swiper-pagination-progressbar-fill {
    position: relative;
    height: inherit;
    width: 100%;
    background: #7c087f;
    display: block;
    transform-origin: left top;
  }
}

.navigation-wrapper {
  display: flex;
  flex-shrink: 0;
  gap: 10px;
}

// ===================== Testimonials Slider ==========================
.testimonials-slider {
  .swiper-slide {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }
}
.testimonials-slider {
  .swiper-slide-testimonials {
    border: 2px solid transparent;
    padding: 30px;

    &:hover {
      background: #f6cbf6a6;
      border: 2px solid #e33de8;
    }
  }
}

.testimonials-slider {
  .influencer-slide {
    opacity: 0.4;

    &.swiper-slide-active {
      opacity: 1;
    }
  }
}

.testimonials-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16;
  margin-top: 40px;

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background: #7c087f2e;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #d633db;
    box-shadow: 0px 0px 40px #d633db;
  }
}

// ===================== Top Categories Slider ==========================
.top-categories-slider {
  padding: 16px 0;

  .swiper-slide {
    width: 266px;
    max-width: 90%;
    padding-top: 140px;
    height: 594px;
    min-height: 270px;
  }

  .swiper-slide-active {
    + .swiper-slide,
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      width: 320px;
      height: 547;
      .slider-element {
        transform: translateY(-70px);

        &:after {
          top: -84px;
        }
      }
    }
    + .swiper-slide + .swiper-slide,
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      .slider-element {
        transform: translateY(-10px);

        &:after {
          top: -125px;
        }
      }
    }
    + .swiper-slide + .swiper-slide + .swiper-slide,
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      width: 261px;
      height: 567;
      .slider-element {
        transform: translateY(-20px);

        &:after {
          top: -54px;
        }
      }
    }
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide,
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      width: 282px;
      height: 546px;
      .slider-element {
        transform: translateY(-40px);

        &:after {
          top: -64px;
        }
      }
    }
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      width: 230;
      height: 547px;
      .slider-element {
        transform: translateY(10px);

        &:after {
          top: -115px;
        }
      }
    }
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      .slider-element {
        transform: translateY(-30px);

        &:after {
          top: -64px;
        }
      }
    }
    + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide
      + .swiper-slide {
      width: 261px;
      height: 567px;
      .slider-element {
        transform: translateY(-50px);
        &:after {
          top: -80px;
        }
      }
    }
  }

  .slider-element {
    height: 100%;
    transition: all 0.3s ease;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: -190px;
      right: 0;
      width: 2px;
      background: #d633db24;
      height: 100%;
      z-index: -10;
      transition: all 0.3s ease;
    }
  }
}

.scrollbar-wrapper {
  padding: 70px 0 50px;
  display: flex;
  justify-content: center;

  & .custom-scrollbar {
    height: 3px;
    background-color: #7c087f;
    border-radius: 2px;
    width: 100%;
    max-width: 207px;
    flex-grow: 1;
    position: relative;
    display: flex;
    overflow: visible;
    align-items: center;

    & .swiper-scrollbar-drag {
      content: '';
      flex-shrink: 0;
      width: 27px;
      height: 27px;
      border-radius: 27px;
      transform: translateX(-50%);
      background: transparent linear-gradient(180deg, #ffc2ff 0%, #7c087f 100%)
        0% 0% no-repeat padding-box;
    }
  }
}

//========================== Pill Navigation =================================
.swiper-pill-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  transform: translateY(-140px);
  z-index: 10;
  position: relative;

  @media (max-width: 1024px) {
    transform: translateY(-100px);
  }

  @media (max-width: 992px) {
    margin-top: 60px;
    transform: translateY(0);
  }

  .swiper-pagination-bullet {
    border-radius: 8px;
    width: 46px;

    height: 6px;
    background: #ffffff;
    opacity: 1;
    outline: none;
  }

  .swiper-pagination-bullet-active {
    width: 28px;
    height: 14px;
    background: #ec40f1;
  }
}

//========================== about Slides =================================

.about-page-slider {
  padding: 25px 20px 40px;

  margin: 0 46px;

  @media (max-width: 767px) {
    padding: 16px 16px 25px;
  }

  .swiper-slide {
    width: 100%;
    height: auto;
  }
}

.about-page-pill-navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 0 66px 40px;
  @media (max-width: 767px) {
    padding: 0 16px 25px;
  }

  .swiper-pagination-bullet {
    margin: 0 6px;
    width: 30px;
    height: 5px;
    background: #bebebe;
    border-radius: 16px;
    opacity: 1;
    outline: none;
  }

  .swiper-pagination-bullet-active {
    background: #c11ec5;
    width: 20px;
    height: 10px;
  }
}
