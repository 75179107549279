@use 'assets/scss/variables' as v;

// =========== normal input
.formControl {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: 350px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid v.$pink-light;

  .prefix {
  }

  .postfix {
  }

  .input {
    width: 100%;
    text-align: left;
    padding: 4px 0px 8px;
    outline: none;
    letter-spacing: 0.15px;
    color: v.$black-light;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 600;
    background: none;
  }
}

.label {
  font-size: v.$spacing-3;
  color: v.$gray-light;
  line-height: 1.4;
  font-weight: 400;
}

// error message

.error {
  color: #f64242 !important;
  margin-top: 6px;
  font-size: v.$spacing-3_5;
}

// ============== radio buttons

.radioInputWrapper {
  composes: inputWrapper;
  flex-wrap: wrap;
  border-bottom-color: transparent;
}

.radioInput {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    display: none;
  }

  .redioMark {
    position: relative;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    margin: 2px;
    height: 20px;
    width: 20px;
    background-color: v.$white;
    border-radius: 50%;
    border: 2px solid v.$primary-dark;

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: transparent;
    }
  }
  input:checked ~ .redioMark:after {
    background-color: v.$primary-dark;
  }

  .checkMark {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid v.$primary-dark;
    border-radius: 0.25rem;
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: v.$white;
  }

  input:checked ~ .checkMark {
    background: v.$primary-dark;

    &:after {
      content: '';
      width: 6px;
      height: 12px;
      border: solid v.$white;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      transform: rotate(45deg) translateY(-1px) translateX(-1px);
    }
  }
  .radioLabel {
    font-size: v.$spacing-3_5;
    line-height: 1.4;
  }
}

//  ============== select

.selectWrapper {
  position: relative;
  line-height: 1;
}
.selectOptions {
  composes: options from 'components/common/CountryCodeSelect/CountryCodeSelect.module.scss';
}
.selectOption {
  composes: option from 'components/common/CountryCodeSelect/CountryCodeSelect.module.scss';

  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  font-size: 14px;
  line-height: 1.4;

  .checkMark {
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid v.$primary-dark;
    border-radius: 0.25rem;
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: v.$white;
  }
}

.singleSelectOptionActive {
  background: rgba(v.$primary, 0.12);
}

.selectOptionActive {
  .checkMark {
    background: v.$primary-dark;

    &:after {
      content: '';
      width: 6px;
      height: 12px;
      border: solid v.$white;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      transform: rotate(45deg) translateY(-1px) translateX(-1px);
    }
  }
}

.selectButton,
.singleSelectButton,
.languageSelectButton {
  text-align: left;
  border-bottom: 1px solid v.$pink-light;
  padding: 4px 0px 4px;
  outline: none;
  letter-spacing: 0.15px;
  color: v.$black-light;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
  background: v.$white;
  width: 100%;
  min-height: 35px;

  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;

  .selectedValues {
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .selectedValue {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 30px;
    background: v.$pink;

    color: v.$white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    .cross {
      padding: 4px;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .dropdownIcon {
    flex-shrink: 0;
  }
}

.singleSelectButton {
  text-align: left;
  padding: 10px 16px;
  outline: none;
  letter-spacing: 0.15px;
  color: v.$primary-dark;
  font-size: 14px;
  border-bottom: 2px solid v.$input-border;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  & .selectButtonValue {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.languageSelectButton {
  cursor: auto;
  padding: 10px 0px 10px 16px;
  border-bottom: 2px solid v.$input-border;
}

.selectButtonFilled {
  border-bottom: 2px solid v.$pink;
}

.selectPlaceholder {
  font-weight: 400;
  color: #828282;
}

// switch =================

.switch {
  width: 26px;
  height: 14px;
  background: #c3c3c3;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  flex: 0 0 auto;

  &.enabled {
    background: #01b000;

    & .dot {
      transform: translateX(13px);
    }
  }

  & .dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: white;
    transition: all 0.3s ease;
    transform: translateX(3px);
  }
}
