.dashboardTop {
  composes: dashboardTop from './search.module.scss';
}

.transBg {
  composes: transBg from './search.module.scss';
}

.searchImg {
  composes: searchImg from './search.module.scss';
}

.categoryButtons {
  composes: categoryButtons from './search.module.scss';
}

.influencerCateBtn {
  composes: influencerCateBtn from './search.module.scss';
}

.whiteActiveBtn {
  composes: whiteActiveBtn from './search.module.scss';
}

.influencerHeader {
  composes: influencerHeader from './search.module.scss';
}

.subCategoryTitle {
  composes: subCategoryTitle from './search.module.scss';
}

.trendingTxt {
  composes: trendingTxt from './search.module.scss';
}

.searchField {
  composes: searchField from './search.module.scss';
}

.campaignDownloadContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.campaignField {
  display: flex;
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.sIcon {
  composes: sIcon from './search.module.scss';
}

.searchTxt {
  composes: searchTxt from './search.module.scss';
}

.filterType {
  background: #f8f9fa;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  outline: none;
  width: 48px;
  height: 48px;
}

.fIcon {
  position: absolute;
  top: 15px;
  left: 14px;
}

.addList {
  composes: addList from './search.module.scss';
}

.profileDiv {
  composes: profileDiv from './search.module.scss';
}

.catgryDiv {
  composes: catgryDiv from './search.module.scss';
}

.catgrySpan {
  composes: catgrySpan from './search.module.scss';
}

.catgryTxt {
  composes: catgryTxt from './search.module.scss';
}

.downloadedTxt {
  composes: downloadedTxt from './search.module.scss';
}

.influencerHeader {
  composes: influencerHeader from './search.module.scss';
}

.categorySelector {
  composes: categorySelector from './search.module.scss';
}

.influencerSelectors {
  composes: influencerSelectors from './search.module.scss';
}

.categryDropdown {
  composes: categryDropdown from './search.module.scss';
}

.activeDropdown {
  composes: activeDropdown from './search.module.scss';
}

.dropdownItem {
  composes: dropdownItem from './search.module.scss';
}

.itemTxt {
  composes: itemTxt from './search.module.scss';
}

.activeText {
  composes: activeTxt from './search.module.scss';
}

.inflNameText {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 500;
  color: #14171f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.igCount {
  display: flex;
  align-items: center;
  gap: 5px;
}

.spCount {
  font-size: 0.75rem;
  font-weight: 800;
}

.deliverablesIcon {
  height: 16px;
  width: 16px;
  object-fit: contain;
}

.igTxt {
  font-size: 0.75rem;
}

.igPlusCount {
  border: 1px solid #7c087f1a;
  background: #ffffff;
  border-radius: 50%;
  padding: 4px 6px;
  font-size: 0.75rem;
  font-weight: 600;
}

.campaignContainer {
  z-index: 1;
  padding: 50px;

  @media (max-width: 992px) {
    padding: 36px 16px;
  }
}

.campignBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  color: #7c287d;
  cursor: pointer;
}

.navSecndTxt {
  color: #cd3395;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  cursor: pointer;
}

.rightArrow {
  composes: rightArrow from './search.module.scss';
}

.campaignHeading {
  display: flex;
  margin-top: 23px;
  gap: 15px;
  align-items: center;
}

.campignTxt {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #19191c;
  font-weight: 600;
}

.campaignList {
  margin-top: 30px;
  display: flex;
  gap: 60px;
  align-items: flex-start;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.campaignInnrList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.headingTxt {
  font-size: 0.875rem;
  color: #19191c;
  line-height: 1.4;
  font-weight: 400;
}

.detailTxt {
  color: #7c087f;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
}

.detailInnrTxt {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color: #14171f;
}

.searchAction {
  position: relative;
}

.costAnalysis {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #402061;
  padding: 10px 25px;
  position: sticky;
  bottom: 2px;
  z-index: 2;
  margin: 0px 50px;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 992px) {
    margin: 0 16px;
  }

  @media (max-width: 640px) {
    position: relative;
    padding: 10px 16px;
  }
}

.totalHeadingTxt {
  font-size: 1rem;
  color: #b3a6c0;
  line-height: 1.4;
  font-weight: 600;
}

.overviewTitle {
  font-size: 0.875rem;
  color: #b3a6c0;
  line-height: 1.4;
  font-weight: 400;
}

.overviewDetail {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color: #ffffff;
}

.downloadImgDiv {
  display: flex;
  gap: 25px;
  cursor: pointer;
}

.meetingNoteTitle {
  text-transform: capitalize;
}

// ======================== campaign Live Tracking Screen ==================
.boxLiner {
  composes: boxLiner from './dashboard.module.scss';
  margin-top: 15px;
}

.totalCount {
  composes: totalCount from './dashboard.module.scss';
}

.cardtransBg {
  composes: cardtransBg from './dashboard.module.scss';
}

.campaignReportContainer {
  padding: 0 50px;
}

.campaignCountVariable {
  display: flex;
  gap: 22px;
  align-items: center;
}

.valueTxt {
  color: #7c087f;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
}

.valueCount {
  color: #ff00a2;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.4;
}

.postingInsightBox {
  border: 1px solid #e6e6e6;
  margin: 25px 0px;
  padding: 15px 15px 24px;
  width: 100%;
}

.postHeader {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.insightHeading {
  color: #616e7c;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.TimeBox {
  display: flex;
  gap: 18px;
  border-bottom: 1px solid #ecdaec;
}

.yearList {
  color: #616e7c;
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
}

.activeYearList {
  color: #d633db;
  font-weight: 600;
  border-bottom: 2px solid #d633db;
}

.postBody {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.graphImg {
  width: 100%;
}

.insightItems {
  display: flex;
  gap: 25px;
  width: 100%;
  flex-wrap: nowrap;

  & > div {
    flex-shrink: 1;
  }

  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
}

.influencerPerformnce {
  display: flex;
  gap: 25px;
  padding-bottom: 25px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.influencerGraphsContainer {
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 992px) {
    // flex-wrap: wrap;
    max-width: 100%;
  }
}

.trendingPostContainer {
  width: 100%;
  max-width: 50%;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    max-width: 100%;
  }
}

.postWrapper {
  composes: postWrapper from './search.module.scss';
  justify-content: left;
  margin-left: 27px;
}

.postBlock {
  composes: postBlock from './search.module.scss';
  max-width: 230px;
}

.propicImg {
  composes: propicImg from './search.module.scss';
  width: 100%;
}

.overlayIcon {
  composes: overlayIcon from './search.module.scss';
}

.bottomBlock {
  composes: bottomBlock from './search.module.scss';
}

.viewsColumn {
  composes: viewsColumn from './search.module.scss';
}

.viewTxt {
  composes: viewTxt from './search.module.scss';
}

.monthTxt {
  composes: monthTxt from './search.module.scss';
}

.trendingPostWrapper {
  // height: 100%;
  // max-height: 486px;
  // overflow: auto;
}

.trendingPostInner {
  height: 100%;
  overflow: auto;
  max-height: 550px;
}

.userPostContnt {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 7px;
}

.profileIm {
  height: 25px;
  width: 25px;
}

.proName {
  color: #14171f;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.benchmarkSectionView {
  composes: benchmarkSectionView from './dashboard.module.scss';
  display: flex;
  gap: 25px;
  width: 100%;
  max-width: 223px;
}

.selctMenu {
  composes: selctMenu from './dashboard.module.scss';
}

.statusContainer {
  display: flex;
  gap: 50px;
}

span.spanColrs {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #7c087f;
}

.statusTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.videostatus {
  padding: 25px 0 0;
}

.titleTxt {
  color: #999e9d;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
}

.titleCount {
  color: #323736;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 5px;
}

// ================
.insigtInnerpopup {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 0px;
  max-height: calc(100vh - 303px);
  overflow: auto;
}

.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crossImg {
  cursor: pointer;
}

.recipePostContainer {
  display: flex;
  gap: 25px;
}

.createdPostPopup {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.innerDiv {
  display: flex;
  align-items: center;
  gap: 25px;
}

.reciNameTxt {
  color: #14171f;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.timelineTxt {
  color: #7b8794;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 6px;
}

.chipBtns {
  margin-top: 5px;
}

.filterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.featherFilterIcon {
  background: transparent linear-gradient(133deg, #7c087f 0%, #a506aa 100%);
  border: 1px solid #e6e6e6;
  padding: 15px 14px;
}

.downloadShareBtn {
  display: flex;
  gap: 20px;
}

// ============ extra =========
.cardField {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
