@use 'assets/scss/variables' as v;
.outerContainer {
  // border: 1px solid #d633dbb0;
  width: 100%;
  box-shadow: 0px 0px 16px v.$pink-lighter;
}
.profileInfo {
  box-shadow: 0px 0px 16px #fff8ff;
  border: 1px solid v.$pink-lighter;
  padding: 40px 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
  .profileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 1.25rem;
      color: v.$black-light;
      line-height: 1.4;
      // margin: 0 0 1rem 0;
      font-weight: 600;
    }
    .editProfileBtn {
      border: none;
      background: none;
      text-decoration: underline;
      font-size: 0.875rem;
      line-height: 1.4;
      color: v.$pink;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.profileContent {
  margin-top: 14px;
  .profileInn {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 567px) {
      flex-wrap: wrap;
      gap: 10px;
    }
    label {
      max-width: 200px;
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.4;
      color: v.$gray-light;
      font-weight: 500;
    }
    p {
      font-size: 0.875rem;
      color: v.$black-light;
      font-weight: 600;
      line-height: 1.4;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
.verifiedBorder {
  box-shadow: 0px 0px 16px #d7b2d8;
  .verifyInnBorder {
    box-shadow: 0px 0px 16px #fff8ff;
    padding: 5px 17px;
    border: 0.30000001192092896px solid #e003e6;
    border-radius: 4px;
    .verifiedTxt {
      color: #7c087f;
      font-size: 0.8125em;
      line-height: 1.4;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
.addressBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 16px;
  gap: 17px;
  border: 0.699999988079071px solid v.$primary;
  background: none;
  font-size: 0.75rem;
  color: v.$primary-dark;
  line-height: 1.5;
  font-weight: 500;
  @media (max-width: 567px) {
    padding: 12px 6px;
    gap: 12px;
  }
}
.addressField {
  border: 1px solid v.$white-light;
  padding: 15px 16px;
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  border-radius: 4px;
  .afLabel {
    font-size: 0.875rem;
    line-height: 1.4;
    color: v.$black-light;
    font-weight: 600;
    display: flex;
    gap: 7px;
    input {
      height: 20px;
      width: 20px;
      border: 1px solid #00000099;
      accent-color: v.$primary-dark;
      cursor: pointer;
    }
  }
  .afP {
    font-size: 0.875rem;
    color: v.$black-light;
    line-height: 1.4;
    font-weight: 500;
  }
}
.activeBorder {
  border: 1px solid #fb6eff;
  border-radius: 4px;
}
.editAddr {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 0.875em;
    color: #3b3b3b;
    line-height: 1.4;
    font-weight: 600;
    overflow-wrap: anywhere;
  }
}
.editAddLink {
  letter-spacing: 0.13px;
  color: #292930;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  text-transform: capitalize;
}

.gstDetail {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.gstInputWrapper {
  max-width: 350px;
  width: 100%;
}

.gstLabel {
  font-size: 0.75em;
  line-height: 1.4;
  color: v.$gray-light;
  font-weight: 400;
}
.gstUpload {
  border-bottom: 1px solid v.$pink-light;
  padding-bottom: 7px;
  position: relative;
  .gstTxt {
    outline: none;
    letter-spacing: 0.15px;
    color: v.$black-light;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 600;
    padding-right: 30px;
  }
  .gstUploadBtnWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 8px;
    max-width: 150px;
  }
  .gstUploadBtn {
    display: flex;
    align-items: center;
    gap: 7px;
    border: 1px dashed v.$primary-dark;
    padding: 8px 16px;
    color: v.$primary-dark;
    font-size: 0.875em;
    line-height: 1.4;
    font-weight: 500;
  }
  .filenameWrapper {
    display: flex;
  }
  .filename {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    color: v.$grey-lighter;
    white-space: nowrap;
  }
}
// ===========================================change password screen

.passwordData {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  label {
    font-size: 0.75em;
    color: v.$gray-light;
    line-height: 1.4;
    font-weight: 400;
  }
  input {
    text-align: left;
    border-bottom: 1px solid v.$pink-light;
    padding: 4px 0px 8px;
    outline: none;
    letter-spacing: 0.15px;
    color: v.$black-light;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 600;
    background: v.$white;
  }
}
.error {
  color: #f64242;
  margin-top: 6px;
  font-size: 14px;
}

.passwordDetail {
  background: v.$white-lighter;
  width: 100%;
  max-width: 350px;
  padding: 10px 12px;
  border-radius: 5px;
  color: v.$gray-light;
  font-size: 0.75r;
  line-height: 1.4;
  font-weight: 400;
  margin: 30px 0px;
}
// ================================== edit profile screen
.editForm {
  display: flex;
  flex-wrap: wrap;
  gap: 29px;
  margin-top: 29px;
}
// =================================== add address screen
.addAddress {
  border: 1px solid v.$white-light;
  border-radius: 4px;
  padding: 15px 20px;
  .text {
    font-size: 0.875;
    line-height: 1.5;
    font-weight: 500;
    color: v.$black-light;
  }
  .locateBtn {
    display: flex;
    align-items: center;
    gap: 11px;
    background: v.$primary-dark;
    cursor: pointer;
    color: v.$white;
    font-size: 0.875;
    line-height: 1.4;
    font-weight: 500;
    padding: 15px;
    border-radius: 5px;
    margin: 20px 0px;
  }
}
.addressForm {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.addressMargin {
  margin-bottom: 0px;
}

// =================== update btn
.submitBtn {
  display: flex;
  gap: 60px;

  .cancelBtn {
    border: none;
    background: none;
    span {
      color: v.$gray-light;
      font-size: 0.875;
      line-height: 1.4;
      font-weight: 500;
    }
  }
}
.saveBtn {
  display: flex;
  align-items: center;
  max-width: 300px;
  justify-content: center;
  width: 100%;
  background: transparent linear-gradient(96deg, #7c087f 0%, #a506aa 100%);
  padding: 10px 0px;
  border: 1px solid v.$primary;
}
.buttonTxt {
  color: v.$white;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  transition: all 0.3s ease;
  display: inline-block;
}
// ========================================= influencer social media account screen
.accountLink {
  display: flex;
  gap: 32px;
  align-items: flex-end;
  @media (max-width: 992px) {
    gap: 18px;
  }
  .socialImage {
    height: 48px;
    width: 48px;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
  }
  .socialTexts {
    box-shadow: -15px 10px 40px #d0d0d059;
    border-bottom: 3px solid v.$primary-dark;
    padding: 18px 38px;
    display: flex;
    gap: 60px;
    margin-bottom: 33px;
    max-width: 440px;
    width: 100%;
    @media (max-width: 992px) {
      flex-wrap: wrap;
      gap: 20px;
    }
    input {
      font-size: 1rem;
      line-height: 1.4;
      color: v.$primary-dark;
      font-weight: 500;
      width: 268px;
      outline: none;
    }
    button {
      cursor: pointer;
      font-size: 0.75em;
      color: v.$primary-dark;
      line-height: 1.4;
      font-weight: 600;
    }
  }
}
// ===========================edit profile screen
.inflencrChoice {
  legend {
    font-size: 0.75em;
    color: #3b3b3b;
    font-weight: 400;
    line-height: 1.4;
  }
  .influencerType {
    display: flex;
    gap: 34px;
    margin-top: 11px;
    label {
      // display: flex;
      // align-items: center;
      // gap: 14px;
      input {
        height: 20px;
        width: 20px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        accent-color: v.$primary-dark;
        cursor: pointer;
      }
      span {
        font-size: 1rem;
        line-height: 1.4;
        color: v.$black-light;
        font-weight: 500;
      }
    }
  }
}

.activeInfluencr {
  color: v.$primary-dark !important;
}
// ====================== radio button design
.staticSelect {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.staticSelect input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: v.$white;
  border-radius: 50%;
  border: 1px solid v.$primary-dark;
}

.radiomark:after {
  content: '';
  position: absolute;
  display: none;
}

.staticSelect input:checked ~ .radiomark:after {
  display: block;
}

.staticSelect .radiomark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: v.$primary-dark;
}
