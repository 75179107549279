.errorContainerWrapper {
  display: grid;
  place-items: center;
  height: 100%;
}

.errorContainer {
  display: grid;
  place-items: center;
  padding: 16px;

  .errorText {
    margin-top: 16px;
    text-align: center;
    font-weight: 600;
    color: #828282;
    letter-spacing: 0.3px;
  }
}

.notFound {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 32px 16px;
  margin: 0 16px;
}
