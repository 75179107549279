@use 'assets/scss/variables' as v;

.wrapper {
  position: relative;
}

.button {
  display: flex;
  gap: v.$spacing-3;
  align-items: center;
  color: v.$txt-color;
  padding: 10px 0 10px 16px;
  background: transparent;
}

.options {
  position: absolute;
  margin-top: v.$spacing-1;
  max-height: v.$spacing-60;
  width: 100%;
  min-width: v.$spacing-56;
  background: v.$white;
  padding: v.$spacing-1 0;
  z-index: 1;
  // border: 1px solid v.$pink-light;
  outline: none;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  overflow-y: auto;
  .option {
    color: v.$txt-color;
    padding: v.$spacing-1_5 v.$spacing-4;
    cursor: pointer;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    &.active {
      background: rgba(v.$primary, 0.16);
      color: v.$primary-dark;
    }
  }
}
