@use 'assets/scss/variables' as v;

.dashboardTop {
  composes: dashboardTop from './dashboard.module.scss';
}

.innerDashboardContent {
  padding: 50px;
  composes: innerDashboardContent from './dashboard.module.scss';

  @media (max-width: 992px) {
    padding: 24px 16px;
  }
}

.campignBreadcrumbs {
  composes: campignBreadcrumbs from './campaign.module.scss';
}

.navTxt {
  composes: navTxt from './campaign.module.scss';
}

.rightArrow {
  composes: rightArrow from './campaign.module.scss';
}

.navSecndTxt {
  composes: navSecndTxt from './campaign.module.scss';
}

.sectionHeader {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    gap: 15px;
    padding: 0 16px;
  }
}

.secLeft {
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    width: 100%;
  }
}

.HeadingTitle {
  color: #19191c;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 600;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    font-size: 1.25rem;
  }
}

.brandButtonsWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 641px) {
    width: 100%;
  }
}

.brandBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #d633db;
  border-radius: 1px;
  box-shadow: inset 0px 0px 5px #fac2fc40, 0px 0px 10px #fac2fc;
  background: #ffffff;
  padding: 11px;
  color: #718096;
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 153px;
  text-align: left;

  & > span {
    flex-grow: 1;
  }

  & > img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }

  @media (max-width: 641px) {
    width: 100%;
  }
}

.secRight {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.addList {
  composes: addList from './search.module.scss';

  @media (max-width: 641px) {
    width: 100%;
  }
}

.searchContent {
  padding: 32px 0px 25px 50px;

  @media (max-width: 992px) {
    padding: 16px;
  }
}

.inputSearch {
  border: 1px solid #ff00a233;
  padding: 19px 23px;
  width: 100%;
  max-width: 837px;
  color: #1b1c31;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
  outline: none;
}

.compareContainer {
  padding: 50px;

  @media (max-width: 992px) {
    padding: 24px 16px;
  }
}

.metricsCardBottom {
  composes: metricsCardBottom from './influencer-profile.module.scss';

  @media (max-width: 992px) {
    margin-top: 0;
  }
}

.platformMetrics {
  gap: 50px;
  composes: platformMetrics from './influencer-profile.module.scss';

  @media (max-width: 992px) {
    gap: 16px;

    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.mtricsLabel {
  color: #9b9c9e;
  composes: mtricsLabel from './influencer-profile.module.scss';
}

.metricsValue {
  margin-top: 5px;
  composes: metricsValue from './influencer-profile.module.scss';
}

.compareWrapper {
  margin-top: 55px;
  display: flex;
  gap: 25px;

  @media (max-width: 992px) {
    margin-top: 24px;
    gpa: 16px;
  }

  @media (max-width: 641px) {
    overflow: auto;
  }
}

.compareTable {
  border: 1px solid #ff00a233;
  border-radius: 8px;
  padding: 25px;

  @media (max-width: 992px) {
    padding: 25px 16px;
  }
}

.detailheadingTxt {
  color: #7c087f;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  white-space: nowrap;
}

.subHeadingTitle {
  margin: 27px 0px;
}

.subTxt {
  color: #718096;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  gap: 10px;
}

.mx25 {
  margin-left: 25px;
}

.influencerCompareWrapper {
  display: flex;
  gap: 25px;
  overflow: auto;
  flex: 1;

  @media (max-width: 641px) {
    gap: 16px;
    overflow: visible;
  }
}

.compareUser {
  padding: 0px;
  width: 244px;
  flex-shrink: 0;
  position: relative;
}

.userProfile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
}

.userProfile img {
  cursor: pointer;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.userSubList {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.userList {
  text-align: center;
}

.userList:nth-child(even) {
  // box-shadow: inset 6px 8px 17px #FAC2FC40, 0px 16px 60px #C3C3C333;
  background: #ff00a20a;
  padding: 15px 0px;
}

.usersName {
  font-weight: bold !important;
  font-size: 0.938rem !important;
}

.userList p {
  color: #14171f;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 500;
}

.selectOptions {
  max-height: 240px;
  width: 100%;
  background: v.$white;
  padding: 4px 0;
  z-index: 1;
  outline: none;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
  overflow: auto;

  & > .selectOption {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: v.$txt-color;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 500;

    &.active {
      background: rgba(v.$primary, 0.12);
    }
  }
}
