.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #616e7c;
  text-align: center;
}

.description {
  font-size: 16px;
  color: #616e7c;
  text-align: center;
}
