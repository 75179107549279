@use 'assets/scss/variables' as v;

.tableContainer {
  background: v.$white;
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ff00a233;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
}

.thead {
  th {
    padding: 16px 0px 16px 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #718096;
    text-align: left;
    white-space: nowrap;

    .head {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .ascIcon {
      transform: rotate(180deg);
    }
    .hideSortIcon {
      visibility: hidden;
    }

    &:last-child {
      padding-right: 16px;
    }
  }
}

.tbody {
  tr:nth-child(odd) {
    background: rgba(255, 0, 162, 0.05);
  }

  &.denseTbody {
    td {
      padding: 16px 0px 16px 25px;
    }
  }

  &.denserTbody {
    td {
      padding: 8px 0px 8px 25px;
    }
  }

  td {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: #14171f;
    padding: 25px 0px 25px 25px;

    &:last-child {
      padding-right: 16px;
    }
  }
}

.tfoot {
  background: #ff00a200;
  border: 2px solid #fee7ff;
  box-sizing: content-box;
}

.checkBox {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid v.$pink;
  border-radius: 0.25rem;
  position: relative;
  display: grid;
  place-content: center;
  cursor: pointer;
  background-color: v.$white;

  &:checked,
  &[data-checked='true'],
  &:indeterminate {
    background-color: v.$pink;

    &:after {
      content: '';
      background-color: transparent;
    }
  }

  &:checked:after,
  &[data-checked='true']:after {
    width: 5px;
    height: 10px;
    border: solid v.$white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translateY(-1px) translateX(-1px);
  }

  &:indeterminate:after {
    width: 8px;
    height: 2px;
    background-color: v.$white;
  }
}

.infoPopover {
  position: relative;
  display: flex;
  align-items: center;

  .popoverPanel {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 0;
    padding: 6px 12px;
    background: v.$white;
    border-radius: 2px;
    box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;
    max-width: 220px;
    width: max-content;
    white-space: initial;
  }
}

// pagination

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 16px 25px;
  overflow-x: auto;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  @media (max-width: 640px) {
    padding: 16px;
    gap: 16px;
  }
}

.paginationWrapper,
.itemCountSelection {
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.paginationButton {
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 2px;

  &.active {
    background-color: v.$pink;
    box-shadow: inset 0px 0px 5px #fac2fc40, 0px 0px 10px #fac2fc;
    color: v.$white;
  }
}

.paginationInput {
  width: 60px;
  text-align: center;
  outline: none;
  background: #ffccec;
  padding: 4px 2px 2px;
  color: #14171f;
  font-weight: 500;
  border-bottom: 2px solid #ff00a2;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.itemCountSelection {
  gap: 14px;

  .perPageSelectionButton {
    padding: 6px 12px;
    background-color: v.$pink;
    box-shadow: inset 0px 0px 5px #fac2fc40, 0px 0px 10px #fac2fc;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    color: v.$white;
    font-weight: 500;
    border-radius: 2px;

    & > svg {
      height: 12px;
      width: 12px;
    }
  }
}

.itemCountSelection,
.paginationWrapper {
  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    flex-shrink: 0;
    white-space: nowrap;
  }
}

.selectDropdown {
  width: 56px;
  z-index: 10;
  background: v.$white;
  box-shadow: inset 0px 0px 10px #fac2fc40, 0px 0px 20px #fac2fc;

  & > .option {
    font-size: 14px;
    padding: 3px 12px;
    cursor: pointer;

    &.active {
      background: rgba(v.$primary, 0.16);
    }
  }
}

tr.tableStateContainer {
  background: white !important;
  height: 285px;

  .stateWrapper {
    display: grid;
    place-items: center;
  }

  .textCenter {
    text-align: center;
  }
}

.errorContainer {
  display: grid;
  place-items: center;
  gap: 20px;

  .errorText {
    font-weight: 700;
    color: #9dadc3;
    letter-spacing: 0.3px;
  }
}
