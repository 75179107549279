@use 'assets/scss/variables' as v;

.header {
  width: 100%;
  background: v.$white;
  z-index: 999;
  overflow: hidden;
  position: relative;
}

.logoSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logoSeparator {
  width: 1px;
  height: 39px;
  background-color: #3b1258;
  flex-shrink: 0;
  display: none;

  @media (min-width: v.$md) {
    display: block;
  }
}

.tagline {
  width: auto;
  max-width: 352px;
  display: none;

  @media (min-width: v.$md) {
    display: block;
  }
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  @media (min-width: v.$md) {
    padding: 12px 32px;
  }

  @media (min-width: v.$xl) {
    padding: 12px 60px;
  }
}

.bottomSection {
  display: flex;
  justify-content: flex-end;
  background: #800783;
  align-items: center;
  padding: 0;
  @media (min-width: v.$xl) {
    padding: 15px 60px;
  }
}

.bottomSectionBrand {
  background: #9d23a0;
}

.bottomSectionInfluencer {
  background: #cf158b;
}

.navContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
  padding: 20px 20px 20px 40px;
  transform: translateX(100%);
  transition: all 0.3s ease;
  background: #ffffff;
  border-left: 1px solid #b7a7c1;
  z-index: 999;

  @media (min-width: v.$xl) {
    background-color: auto;
    position: relative;
    height: auto;
    width: auto;
    padding: 0;
    transform: translateX(0);
    border-left: none;
    background: inherit;
    color: white;
  }

  &.show {
    transform: translateX(0);
  }
}

.closeBtnSpacer {
  height: 84px;

  @media (min-width: v.$xl) {
    display: none;
  }
}

.actionBtnSection {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: v.$xl) {
    gap: 16px;
  }

  .navLinksWrapper {
    display: none;
    @media (min-width: v.$xl) {
      display: flex;
      gap: 16px;
    }
  }
}

.socialLinks {
  display: flex;
  gap: 16px;

  & img {
    width: 36px;
    height: 36px;

    @media (min-width: v.$md) {
      width: 52px;
      height: 52px;
    }
  }
}

.actionBtnSectionMobile {
  display: block;
  @media (min-width: v.$xl) {
    display: none;
  }
}

.logo {
  width: 130px;
  @media (min-width: v.$xl) {
    width: auto;
    height: 100px;
  }
}

.navLinks {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;

  @media (min-width: v.$xl) {
    flex-direction: row;
    gap: 54px;
    align-items: center;
  }
}
.navItems {
  text-decoration: none;
  font-size: 1em;
  line-height: 1.4;
  color: #0e0e0e;
  font-weight: 500;

  @media (min-width: v.$xl) {
    color: white;
  }
}

.navBtn {
  display: inline-block;
  z-index: 1000;
  position: absolute;
  top: 26px;
  right: 30px;
  @media (min-width: v.$xl) {
    display: none;
  }
}

.mobileNavBtn {
  z-index: 1000;
  display: inline-block;
  @media (min-width: v.$xl) {
    display: none;
  }
}

.navCheck {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
  width: 50px;
  transform: translateX(4px);
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;

  span {
    flex-shrink: 0;
    background: v.$primary-dark;
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:nth-child(1) {
      width: 32px;
    }

    &:nth-child(3) {
      width: 28px;
    }
  }

  &.navOpen {
    span:nth-child(3) {
      transform: rotate(-45deg);
      transform-origin: left;
      width: 35px;
    }
    span:nth-child(2) {
      transform: translateX(-40px);
      opacity: 0;
    }
    span:nth-child(1) {
      transform: rotate(45deg);
      transform-origin: left;
      width: 35px;
    }
  }
}

.signInBtn {
  color: #900794;
  font-weight: 600;
}

.signInBtnInfluencer {
  color: #0e0e0e;
  font-weight: 600;
}

.signupBtn {
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #fff;
  transition: all 0.2s ease;
  background: transparent linear-gradient(110deg, #7c087f 0%, #a506aa 100%) 0%
    0% no-repeat padding-box;
}

.influencerSignUpBtn {
  border: 1px solid #ff00a2;
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #ff00a2;
  transition: all 0.2s ease;
  background: none;

  &:hover {
    box-shadow: 0px 0px 4px #ff9ddb;
    background: #ff00a21a;
  }
}

.brandSignUpBtn {
  border: 1px solid #7c087f;
  padding: 16px 41px;
  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 600;
  color: #7c087f;
  transition: all 0.2s ease;
  background: none;

  &:hover {
    background: #d633db1a;
    box-shadow: 0px 0px 4px #fca2ff99;
  }
}

.signInDialogPanel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;

  & > a {
    padding: 16px 32px;
    border: 1px solid #d61fdb;
    background: #ffffff;
    color: #7c087f;
    font-size: 1.25rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 660px) {
    gap: 40px;

    & > a {
      width: 278px;
    }
  }

  @media (min-width: 1024px) {
    gap: 60px;

    & > a {
      padding: 20px 40px;
      font-size: 1.5rem;
      width: 360px;
    }
  }

  @media (min-width: 1280px) {
    gap: 87px;

    & > a {
      padding: 32px 54px;
      font-size: 1.875rem;
      width: 432px;
    }
  }
}
